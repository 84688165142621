import React from 'react'

import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { DetailViewHeaderEyebrowAttribute } from 'features/views/DetailView/attributes/DetailViewHeaderEyebrowAttribute'
import { DetailViewProfileImageAttribute } from 'features/views/DetailView/attributes/DetailViewProfileImageAttribute'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewTopBarActionButtonState } from './hooks/useDetailViewTopBarActionButtonState'
import { useDetailViewTopBarActionsState } from './hooks/useDetailViewTopBarActionsState'
import { useDetailViewTopBarQuickActionsState } from './hooks/useDetailViewTopBarQuickActionsState'
import { useDetailViewTopBarState } from './hooks/useDetailViewTopBarState'
import { DetailViewTopBarBreadcrumbs } from './DetailViewTopBarBreadcrumbs'

type DetailViewTopBarProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const DetailViewTopBar: React.FC<DetailViewTopBarProps> = (props) => {
    const { isLoading, showMenuButton, showGoBackButton, goBack } = useDetailViewTopBarState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box flex center justifyContent="space-between" gap="s" {...props}>
            <Box flex center gap={{ mobile: 's', tablet: 'm' }} shrink grow>
                {isMobile && showGoBackButton && (
                    <Button
                        size="xs"
                        variant="ghost"
                        startIcon={{ name: 'ArrowLeft' }}
                        onClick={goBack}
                    />
                )}
                {showMenuButton && (
                    <Box flex center>
                        <CollapsedNavigationTrigger />
                    </Box>
                )}
                {!isMobile && (
                    <Skeleton isLoading={isLoading}>
                        <Box flex center grow shrink gap="xs">
                            <DetailViewTopBarBreadcrumbs />
                            <Box flex center shrink grow gap="m">
                                <DetailViewProfileImageAttribute noShrink />
                                <DetailViewTitleAttribute size="l" />
                                <DetailViewHeaderEyebrowAttribute />
                            </Box>
                        </Box>
                    </Skeleton>
                )}
            </Box>
            <DetailViewTopBarQuickActions />
        </Box>
    )
}

type DetailViewTopBarQuickActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {}

const DetailViewTopBarQuickActions: React.FC<DetailViewTopBarQuickActionsProps> = ({
    style,
    ...props
}) => {
    const { copyLink, duplicateRecord, deleteRecord, actions } =
        useDetailViewTopBarQuickActionsState()

    const showAsDropdown = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box
            flex
            center
            gap="s"
            shrink
            style={{
                minWidth: 'min-content',
                ...style,
            }}
            {...props}
        >
            {!showAsDropdown && <DetailViewTopBarActions actions={actions} />}
            <Box flex center gap="s">
                {!!copyLink && !showAsDropdown && (
                    <Tooltip side="bottom" align="center" content="Copy link" zIndex={200} asChild>
                        <Button
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'Link' }}
                            aria-label="Copy link"
                            onClick={copyLink}
                            noShrink
                        />
                    </Tooltip>
                )}
                {!!duplicateRecord && !showAsDropdown && (
                    <Tooltip side="bottom" align="center" content="Duplicate" zIndex={200} asChild>
                        <Button
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'Copy' }}
                            aria-label="Duplicate"
                            onClick={duplicateRecord}
                            noShrink
                        />
                    </Tooltip>
                )}
                {!!deleteRecord && !showAsDropdown && (
                    <Tooltip side="bottom" align="center" content="Delete" zIndex={200} asChild>
                        <Button
                            size="s"
                            variant="critical"
                            startIcon={{ name: 'Trash2' }}
                            aria-label="Delete"
                            onClick={deleteRecord}
                            noShrink
                        />
                    </Tooltip>
                )}
                {showAsDropdown && (
                    <Dropdown>
                        <DropdownButton
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'MoreHorizontal' }}
                            aria-label="More actions"
                            noShrink
                        />
                        <DropdownContent
                            onClick={stopPropagation}
                            align="end"
                            side="bottom"
                            style={{ minWidth: '186px' }}
                        >
                            {actions.length > 0 && (
                                <>
                                    {actions.map((action) => (
                                        <DetailViewTopBarActionDropdownItem
                                            key={action._sid}
                                            action={action}
                                            startIcon={{ name: 'Workflow' }}
                                            closeOnSelect
                                        />
                                    ))}
                                    <DropdownSeparator />
                                </>
                            )}
                            {!!copyLink && (
                                <DropdownItem
                                    label="Copy link"
                                    onClick={copyLink}
                                    startIcon={{ name: 'Link' }}
                                />
                            )}
                            {!!duplicateRecord && (
                                <DropdownItem
                                    label="Duplicate"
                                    onClick={duplicateRecord}
                                    startIcon={{ name: 'Copy' }}
                                />
                            )}
                            {!!deleteRecord && (
                                <DropdownItem
                                    variant="destructive"
                                    label="Delete"
                                    onClick={deleteRecord}
                                    startIcon={{ name: 'Trash2' }}
                                />
                            )}
                        </DropdownContent>
                    </Dropdown>
                )}
            </Box>
        </Box>
    )
}

type DetailViewTopBarActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    actions?: ActionDto[]
}

const DetailViewTopBarActions: React.FC<DetailViewTopBarActionsProps> = ({
    actions,
    style,
    ...props
}) => {
    const { visibleActions, menuActions } = useDetailViewTopBarActionsState({ actions })

    if (!visibleActions.length && !menuActions.length) return null

    return (
        <Box
            display="grid"
            style={{
                gridTemplateColumns: `repeat(${visibleActions.length + (!!menuActions.length ? 1 : 0)}, minmax(10px, max-content))`,
                minWidth: `${(visibleActions.length + (!!menuActions.length ? 1 : 0)) * 32}px`,
                flexShrink: 1,
                ...style,
            }}
            gap="s"
            {...props}
        >
            {visibleActions.map((action, idx) => (
                <DetailViewTopBarActionButton
                    key={action._sid}
                    action={action}
                    size="s"
                    variant={idx === 0 ? 'primary' : 'secondary'}
                />
            ))}
            {menuActions.length > 0 && (
                <Dropdown>
                    <DropdownButton
                        size="s"
                        variant="secondary"
                        startIcon={{ name: 'MoreHorizontal' }}
                        noShrink
                    />
                    <DropdownContent align="end" side="bottom" style={{ minWidth: '286px' }}>
                        {menuActions.map((action) => (
                            <DetailViewTopBarActionDropdownItem
                                key={action._sid}
                                action={action}
                                closeOnSelect
                            />
                        ))}
                    </DropdownContent>
                </Dropdown>
            )}
        </Box>
    )
}

type DetailViewTopBarActionButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    action: ActionDto
}

const DetailViewTopBarActionButton: React.FC<DetailViewTopBarActionButtonProps> = ({
    action,
    ...props
}) => {
    const { handleClick } = useDetailViewTopBarActionButtonState({ action })

    return (
        <Button {...props} onClick={handleClick}>
            <Box
                trim
                maxWidth="full"
                style={{
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    marginTop: '-2px',
                    marginBottom: '-2px',
                }}
            >
                {action.name}
            </Box>
        </Button>
    )
}

type DetailViewTopBarActionDropdownItemProps = React.ComponentPropsWithoutRef<
    typeof DropdownItem
> & {
    action: ActionDto
}

const DetailViewTopBarActionDropdownItem: React.FC<DetailViewTopBarActionDropdownItemProps> = ({
    action,
    ...props
}) => {
    const { handleClick } = useDetailViewTopBarActionButtonState({ action })

    return <DropdownItem label={action.name} {...props} onClick={() => handleClick()} />
}
