import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewTopBarState() {
    const { recordManager, prevBreadcrumb } = useDetailViewContext()

    const isLoading = recordManager.isFetchingSlow

    const { showCollapsedNavigationTrigger } = useNavigationContext()
    const showMenuButton = showCollapsedNavigationTrigger

    const history = useHistory()
    const showGoBackButton = !!prevBreadcrumb
    const goBack = useCallback(() => {
        if (showGoBackButton) {
            history.push(prevBreadcrumb.path)
        }
    }, [showGoBackButton, history, prevBreadcrumb?.path])

    return useMemo(
        () => ({
            isLoading,
            showMenuButton,
            goBack,
            showGoBackButton,
        }),
        [isLoading, showMenuButton, goBack, showGoBackButton]
    )
}
