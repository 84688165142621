import React from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'

type DetailViewTopBarBreadcrumbsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    size?: React.ComponentProps<typeof Body>['size']
}

export const DetailViewTopBarBreadcrumbs: React.FC<DetailViewTopBarBreadcrumbsProps> = ({
    style,
    size = 'l',
    ...props
}) => {
    const { breadcrumbs } = useDetailViewContext()

    return (
        <Box
            shrink
            flex
            center
            gap="xs"
            style={{ userSelect: 'none', minWidth: `${breadcrumbs.length * 32}px`, ...style }}
            {...props}
        >
            {breadcrumbs.map((breadcrumb, idx) => {
                if (breadcrumb.type === 'list') {
                    return (
                        <>
                            {idx > 0 ? (
                                <Link
                                    key={breadcrumb.path}
                                    to={breadcrumb.path}
                                    shrink
                                    trim
                                    style={{ minWidth: '32px' }}
                                >
                                    <Body
                                        size={size}
                                        weight="bold"
                                        color={{
                                            default: 'text',
                                            hover: 'theme600',
                                            active: 'theme700',
                                        }}
                                        trim
                                    >
                                        {breadcrumb.label}
                                    </Body>
                                </Link>
                            ) : (
                                <Body size={size} weight="bold" color="text" trim>
                                    {breadcrumb.label}
                                </Body>
                            )}
                            <Icon name="ChevronRight" size="m" color="icon" noShrink />
                        </>
                    )
                }

                return null
            })}
        </Box>
    )
}
