import React from 'react'

import { Spinner } from '@chakra-ui/react'
import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'

import { useAppContext } from 'app/useAppContext'
import { useGetRecord } from 'data/hooks/records'
import { useBreadcrumbsContext } from 'features/breadcrumbs/hooks/useBreadcrumbsContext'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { LinkButton } from 'ui/components/LinkButton'

type RecordLinkComponentProps = NodeViewRendererProps & {}
export const RecordLinkComponent: React.FC<RecordLinkComponentProps> = ({ node, extension }) => {
    const { id, stack_id, url } = node.attrs

    let recordId = id
    // In case this is an encoded record id, we need to extract the record id.
    if (recordId.includes('|')) {
        const parts = recordId.split('|')
        recordId = parts[2]?.replace('record_', '')
    }

    const { selectedStack } = useAppContext()

    const { navigateTo } = useBreadcrumbsContext()
    const records = (extension.options.fetchRecordsFn?.() as RecordDto[]) ?? []
    let record = records.find((r) => r._sid === recordId)

    const { data, isLoading } = useGetRecord({
        recordId: recordId,
        includeFields: [],
        options: { stackId: stack_id },
        useQueryOptions: { enabled: !record },
    })
    record = record ?? data

    if (!recordId) return null

    const isExternal = selectedStack?._sid !== stack_id

    // the record is unavailable due to permissions
    const isInaccessible = !isExternal && !record && !isLoading

    const title = isInaccessible ? 'Inaccessible Record' : (record?._primary ?? recordId)

    const treatAsExternalLink = isExternal || isInaccessible

    return (
        <NodeViewWrapper as="span" className="record-link">
            <LinkButton
                to={url}
                target={treatAsExternalLink ? '_blank' : undefined}
                variant="link"
                size="2xs"
                title={title}
                aria-disabled={isLoading}
                flex
                center
                fontWeight="bodyBold"
                maxWidth="full"
                flexWrap="nowrap"
                style={{
                    userSelect: 'text',
                }}
                data-drag-handle
                draggable="true"
                onClick={(e) => {
                    if (treatAsExternalLink || !record) {
                        return
                    }

                    navigateTo({
                        type: 'detail',
                        recordSid: recordId,
                        object: { _sid: record?._object_id } as ObjectDto,
                        openAs: 'preview',
                        stack: selectedStack!,
                    })
                    e.preventDefault()
                }}
            >
                <Box>{isLoading ? <Spinner size="xs" /> : <Icon name="FileText" />}</Box>
                <Box as="span" width="full" trim>
                    {title}
                </Box>
                {treatAsExternalLink && <Icon name="ExternalLink" size="s" />}
            </LinkButton>
        </NodeViewWrapper>
    )
}
