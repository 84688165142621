/**
 * Calculate the sync state and last sync time for given data connections.
 * More than one data connection can be provided, in which case the aggregate sync state is returned
 *
 * @param dataConnections Array of DataConnectionDto to calculate sync info for
 * @returns Object containing whether sync is in progress and the latest sync time
 */
type SyncState = {
    hasAuthError: boolean
    isSyncInProgress: boolean
    earliestStatus: DataConnectionDto['status']
    lastSyncTime: Date | null
}

export const calculateSyncState = (dataConnections: DataConnectionDto[]): SyncState => {
    // when aggregating the status of multiple DCs, the status with the lowest number is considered as earlier and
    // prioritised (so if one DC is in completed status, another is in nango_started status, the aggregate status is
    // nango_started)
    const STATUS_PRIORITY: Record<DataConnectionDto['status'], number> = {
        AUTHENTICATION_ERROR: 1,
        AUTHORIZATION_ERROR: 2,
        UNKNOWN_ERROR: 3,
        SYNC_NOT_STARTED: 4,
        NANGO_STARTED: 5,
        NANGO_COMPLETED: 6,
        SYNCING_SCHEMA: 7,
        SCHEMA_SYNC_COMPLETED: 8,
    }

    let maxLastSyncTime: Date | null = null
    let earliestStatus: DataConnectionDto['status'] = 'SCHEMA_SYNC_COMPLETED'

    for (const dataConnection of dataConnections) {
        if (STATUS_PRIORITY[dataConnection.status] < STATUS_PRIORITY[earliestStatus]) {
            earliestStatus = dataConnection.status
        }
        if (
            dataConnection.status === 'SCHEMA_SYNC_COMPLETED' &&
            dataConnection.last_sync_completed_time
        ) {
            const dcLastSyncTime = new Date(dataConnection.last_sync_completed_time)
            if (maxLastSyncTime === null || dcLastSyncTime > maxLastSyncTime) {
                maxLastSyncTime = dcLastSyncTime
            }
        }
    }

    const hasAuthError = ['AUTHENTICATION_ERROR', 'AUTHORIZATION_ERROR', 'UNKNOWN_ERROR'].includes(
        earliestStatus
    )
    return {
        hasAuthError,
        isSyncInProgress: !hasAuthError && earliestStatus !== 'SCHEMA_SYNC_COMPLETED',
        earliestStatus,
        lastSyncTime: maxLastSyncTime,
    }
}
