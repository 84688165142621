import { useAccounts } from 'data/hooks/accounts'

import { useAppContext } from './useAppContext'
import { useAppUserContext } from './useAppUserContext'

export default function MetaDataRefresher() {
    const { workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()

    // If we have a workspace account and we're authenticated, then set up to refresh the accounts
    // from the server periodically. This allows use to pick up changes, particularly related to account auth configuration.
    useAccounts({
        refetchInterval: workspaceAccount && user ? 60000 : undefined,
    })
    return null
}
