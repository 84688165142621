import React, { useContext } from 'react'

import { BreadcrumbsItem } from 'features/breadcrumbs/types'
import { DetailViewHeader } from 'features/views/DetailView/types'
import { RecordManager } from 'features/views/RecordManager/hooks/useRecordManagerState'

export type DetailViewContextValue = {
    view?: ViewDto
    viewPath?: string
    object?: ObjectDto
    fields: FieldDto[]
    recordManager: RecordManager
    dereferencedRecords: Partial<RecordDto>[]
    header: DetailViewHeader
    deleteRecord: () => void
    duplicateRecord: () => void
    isPreviewing: boolean
    breadcrumbs: BreadcrumbsItem[]
    prevBreadcrumb?: BreadcrumbsItem
    requestIncludedFields: string[]
    contentWidth: 'full' | 'boxed'
    widgetStyle: 'compact' | 'spacious'
}
export const DetailViewContext = React.createContext<DetailViewContextValue | null>(null)

export function useDetailViewContext(): DetailViewContextValue {
    const context = useContext(DetailViewContext)

    if (!context) {
        throw new Error('useDetailViewContext must be used within a DetailViewContextProvider')
    }

    return context
}
