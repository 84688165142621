import React, { useCallback } from 'react'

import { Box } from 'ui/components/Box'
import { LinkButton } from 'ui/components/LinkButton'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useUrlAttributeDisplayState } from './hooks/useUrlAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type UrlAttributeTagProps = React.ComponentPropsWithoutRef<typeof LinkButton> & {
    openInNewTab?: boolean
    buttonTitle?: string
    displayAsImage?: boolean
    imageAltText?: string
    maxLength?: number
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
    showIcon?: boolean
}

export const UrlAttributeTag: AttributeDisplayComponent<string, UrlAttributeTagProps> = React.memo(
    function UrlAttributeTag({
        field,
        value,
        style,
        openInNewTab,
        buttonTitle,
        displayAsImage,
        onClick,
        maxLength,
        showOverflowTooltip = true,
        maxOverflowLength,
        isLoading,
        showIcon,
        ...props
    }) {
        const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                onClick?.(e as React.MouseEvent<HTMLAnchorElement>)
            },
            [onClick]
        )

        const { target, to, href, label, isOverflowing, overflowLabel, icon } =
            useUrlAttributeDisplayState({
                field,
                value,
                openInNewTab,
                buttonTitle,
                maxLength,
                isLoading,
                showIcon,
            })

        if (!href) return null

        if (displayAsImage) {
            return (
                <Skeleton isLoading={isLoading}>
                    <LinkButton
                        variant="secondary"
                        size="2xs"
                        href={href}
                        to={to}
                        target={target}
                        onClick={handleClick}
                        noShrink
                        startIcon={icon ?? { name: 'Image' }}
                        style={style}
                        {...props}
                    />
                </Skeleton>
            )
        }

        const content = (
            <Skeleton isLoading={isLoading}>
                <LinkButton
                    variant="secondary"
                    size="2xs"
                    href={href}
                    to={to}
                    target={target}
                    style={{ wordBreak: 'break-word', ...style }}
                    onClick={handleClick}
                    noShrink
                    startIcon={icon}
                    {...props}
                >
                    <Box trim maxWidth="full" py="3xs">
                        {label}
                    </Box>
                </LinkButton>
            </Skeleton>
        )

        if (isOverflowing && effectiveShowOverflowTooltip) {
            return (
                <Tooltip
                    content={
                        <Body
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                            maxLength={maxOverflowLength}
                        >
                            {overflowLabel}
                        </Body>
                    }
                    zIndex={200}
                    side="bottom"
                    asChild
                >
                    {content}
                </Tooltip>
            )
        }

        return content
    }
)
