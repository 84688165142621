import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import { PreviewRecordContextProvider } from 'v2/views/List/PreviewRecord/PreviewRecordContextProvider'

import { getCurrentStack, getWorkspaceAccount } from 'app/GlobalStaticState'
import settings from 'app/settings'
import { getUrl, trimRootPathFromUrl, Urls } from 'app/UrlService'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { withNavigation } from 'data/wrappers/withNavigation'
import { withObjects } from 'data/wrappers/withObjects'
import { withPages } from 'data/wrappers/withPages'
import { withUser } from 'data/wrappers/withUser'
import { withViews } from 'data/wrappers/withViews'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { BreadcrumbsContextProvider } from 'features/breadcrumbs/BreadcrumbsContextProvider'
import { Frame } from 'features/core/Frame'
import { viewHasAssociatedPage } from 'features/core/nav/viewHasAssociatedPage'
import getPageFromUrl from 'features/pages/utils/getPageFromUrl'
import { PathContext } from 'features/utils/PathContext'
import { getRedirectParam } from 'utils/utils'

import Page from './Page'

class _PageByUrl extends React.Component {
    /*
        PageByUrl works out which page to view based on the url, and displays it, potentially in a studio surround
    */

    constructor(props) {
        super(props)
        // debugger
        invalidateObjects()
        this.state = { isConnectionEditorOpen: false }
    }

    showMainPageForObject = (object) => {
        const objectPages = this.props.pages
            .filter((page) => page.object_id === object._sid)
            .filter((page) => page.url.indexOf('{') === -1) // don't include detail pages anyway
            .filter((page) => {
                const view = this.props.views.find(({ _sid }) => _sid === page.options.view_id)
                return view?.type === 'list'
            }) // Only show a list page
        return objectPages.length ? objectPages[0] : null
    }

    getViewPage = (view) => {
        const viewPage = this.props.pages.filter((page) => page.options.view_id == view._sid)

        return viewPage.length ? viewPage[0] : null
    }

    homePage = () => {
        // We return the first page displayed on the item menu
        const { views, objects, navigation, pages } = this.props

        const isSecondaryNavigation =
            localStorage.getItem('secondary_navigation') ||
            getCurrentStack()?.options?.secondary_navigation

        if (isSecondaryNavigation) {
            if (navigation && navigation.length > 0) {
                const navTree = buildNavTree(navigation, views, pages).filter((n) => !n.hidden)

                for (let i = 0; i < navTree.length; i++) {
                    const visibleChildren = navTree[i].children.filter((x) => !x.hidden)
                    if (visibleChildren?.length > 0) {
                        return visibleChildren[0]
                    }
                }
            }
            // all pages are hidden or unavailable
            return undefined
        } else {
            const objectList = mapKeys(objects, (o) => o._sid)
            const rankedViews = sortBy(views, (v) => v.name).filter(
                (view) =>
                    !view.options.hide_from_menu &&
                    view.type == 'list' &&
                    view.object_id in objectList &&
                    viewHasAssociatedPage(view, pages)
            )
            return rankedViews.length ? rankedViews[0] : null
        }
    }

    setConnectionEditorOpen = (isConnectionEditorOpen) => {
        this.setState({ isConnectionEditorOpen })
    }

    render() {
        const route = trimRootPathFromUrl(this.props.location.pathname).slice(1)

        const pages = this.props.pages

        let { page, object, captures, pageUrl, view } = getPageFromUrl({
            objects: this.props.objects,
            pages,
            route,
            views: this.props.views,
        })

        const wrap = (contents) => <Frame>{contents}</Frame>

        const { isConnectionEditorOpen } = this.state
        if (!page) {
            if (
                this.props.pages.length === 0 ||
                this.props.objects.length === 0 ||
                this.props.views.length === 0
            ) {
                // Page is still loading
                if (!this.props.user) {
                    // If we're trying to log into a workspace, redirect to the
                    // studio for login. It will redirect back to the workspace.
                    const workspaceAccount = getWorkspaceAccount()
                    const redirectTo = getRedirectParam()
                    if (workspaceAccount) {
                        window.location.assign(`${settings.STUDIO_URL}/login?r=${redirectTo}`)
                    } else {
                        window.location.assign(`${getUrl(Urls.Login)}?r=${redirectTo}`)
                    }
                }
                return wrap('')
            } else {
                // If we're at the root of the object, then we literally just choose one of the pages
                // TODO: find a better way to do this

                if (view) {
                    page = this.getViewPage(view)
                } else if (object && pageUrl === '/') {
                    const objectMainPage = this.showMainPageForObject(object)
                    if (objectMainPage) {
                        page = objectMainPage
                    }
                } else if (!object && (pageUrl === Urls.Home || pageUrl === '/')) {
                    // Home is our homepage, and / is for catchall
                    // We've not found a page or a root object, so let's choose the first page that appears on the menu bar
                    const homeView = this.homePage()
                    if (homeView && !isConnectionEditorOpen) {
                        this.props.history.replace(getUrl(homeView.url))
                        // the page will re-render now that the location is changing
                        // but in the meantime, we want to return null and not render
                        // <Page /> below or it may initiate a redirect itself because we don't
                        // yet have a page
                        return null
                    }
                } else {
                    // Otherwise probably just show a four oh four
                    const pageIsEditing = this.props.location.hash === '#edit'

                    if (pageIsEditing) {
                        return wrap(`Page ${route} does not exist`)
                    } else {
                        // Finished loading pages but page is not valid, so we load 404 page instead
                        // Get /stacker/no-access or /stacker/page-not-found page
                        const route404 = this.props.isLoggedIn
                            ? '/stacker/page-not-found'
                            : '/stacker/no-access'
                        const result404 = getPageFromUrl({
                            objects: this.props.objects,
                            pages,
                            route: route404,
                        })
                        if (!result404.page) {
                            // Just redirect to home if we have no page that matches this URL any more
                            return <Redirect to={getUrl(Urls.Home)} />
                        } else {
                            // Replace the page we were going to load with the 404 page
                            page = result404.page
                            captures = result404.captures
                        }
                    }
                }
            }
        }

        return (
            <PathContext.Provider
                value={{
                    pageUrl: '/' + route,
                    page,
                    object,
                    listView: get(view, 'type') === 'list' ? view : null,
                    recordId: get(captures, 'id'),
                    view: view,
                }}
            >
                <PreviewRecordContextProvider>
                    <BreadcrumbsContextProvider>
                        <Page
                            updatePage={this.props.updatePage}
                            view={view}
                            page={page}
                            route={route}
                            captures={captures}
                            object={object}
                            setConnectionEditorOpen={this.setConnectionEditorOpen}
                            isConnectionEditorOpen={isConnectionEditorOpen}
                        />
                    </BreadcrumbsContextProvider>
                </PreviewRecordContextProvider>
            </PathContext.Provider>
        )
    }
}

_PageByUrl.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired, // From withPages
    isLoggedIn: PropTypes.bool.isRequired, // From withUser
    userActions: PropTypes.object.isRequired, // From withUser
    user: PropTypes.object.isRequired, // From withUser
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    }
}

export const PageByUrl = withNavigation(
    withUser(
        withRouter(withObjects(withPages(withViews(connect(null, mapDispatchToProps)(_PageByUrl)))))
    )
)
