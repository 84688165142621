import React from 'react'

import { assertIsDefined } from 'data/utils/ts_utils'
import { DEFAULT_ACTION_ICON } from 'features/workflows/common'
import { ConnectionDropdown } from 'features/workflows/Integrations/ConnectionDropdown'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { StepIconInput } from 'features/workflows/StepIconInput'
import { WorkflowNodeEditorComponent } from 'features/workflows/types'
import { useWorkflowEditorContext } from 'features/workflows/useWorkflowEditorContext'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'

import { ActionInputGroup } from './ActionInputGroup'
import { APIRequestActionConfig } from './APIRequestAction'
import { CommentOnRecordActionConfig } from './CommentOnRecordAction'
import { CreateRecordActionConfig } from './CreateRecordAction'
import { CreateTaskActionConfig } from './CreateTaskAction'
import { FindRecordsActionConfig } from './FindRecordsAction'
import { WorkflowActionEditorComponent } from './types'
import { UpdateRecordActionConfig } from './UpdateRecordAction'
import { usePreparedActionSchema } from './usePreparedActionSchema'

type ActionEditorProps = {
    action: WorkflowActionConfig
    onChange: (action: Omit<Partial<WorkflowActionConfig>, 'id'>) => void
}

export const ActionEditor: WorkflowNodeEditorComponent<ActionEditorProps> = ({
    action,
    onChange,
}) => {
    const { contextSchema } = useWorkflowEditorContext()
    const actionType = usePreparedActionSchema(action)

    const ConfiguratorComponent = ActionConfigurators[action.action_type]
    assertIsDefined(actionType)

    return (
        <>
            <Box flex column stretch p="xl">
                <ConfigurationLabel mt="xs">Action</ConfigurationLabel>
                <Box
                    flex
                    center
                    width="full"
                    borderWidth="base"
                    borderStyle="base"
                    borderColor="border"
                    background="surfaceStrongest"
                    borderRadius="s"
                    py="xs"
                    px="m"
                    fontSize="bodyM"
                >
                    <ItemIcon
                        icon={actionType.icon || DEFAULT_ACTION_ICON}
                        iconStyle={actionType.icon_style}
                        iconUrl={action.icon?.url}
                        mr="m"
                    />
                    {actionType.name}
                </Box>
                {ConfiguratorComponent && (
                    <ConfiguratorComponent
                        action={action}
                        actionType={actionType}
                        onChange={onChange}
                    />
                )}
                <ConfigurationLabel>Step label</ConfigurationLabel>
                <Input value={action.name} onChange={(e) => onChange({ name: e.target.value })} />
                <StepIconInput action={action} onChange={onChange} />
                {actionType.auth && (
                    <>
                        <ConfigurationLabel>Connection</ConfigurationLabel>
                        <ConnectionDropdown
                            value={action.settings?.connection_id as string | undefined}
                            onChange={(connection) =>
                                onChange({
                                    settings: {
                                        ...action.settings,
                                        connection_id: connection.id,
                                    },
                                })
                            }
                            providerId={actionType.auth.provider_id}
                        />
                    </>
                )}
            </Box>

            <Divider my="m" />

            <Box flex column stretch px="xl">
                {actionType.inputs && (
                    <ActionInputGroup
                        title="Inputs"
                        items={actionType.inputs}
                        contextSchema={contextSchema}
                        action={action}
                        onChange={onChange}
                    />
                )}
            </Box>
        </>
    )
}

const ActionConfigurators: Record<string, WorkflowActionEditorComponent> = {
    create_record: CreateRecordActionConfig,
    update_record: UpdateRecordActionConfig,
    comment_on_record: CommentOnRecordActionConfig,
    create_task: CreateTaskActionConfig,
    find_records: FindRecordsActionConfig,
    api_request: APIRequestActionConfig,
}
