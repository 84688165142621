import React from 'react'
import { Redirect } from 'react-router-dom'

import settings from 'app/settings'
import { Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'
import { useDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useObjects } from 'data/hooks/objects'
import { useIsCurrentUserStackerSupport } from 'utils/supportLogin'

import { Box } from 'ui/components/Box'
import { Spinner } from 'ui/components/Spinner'

export const DataConnectionSupportView = () => {
    const isCurrentUserStackerSupport = useIsCurrentUserStackerSupport()
    const { isAdmin } = useAppUserContext()
    const isEnabled = isCurrentUserStackerSupport && isAdmin
    const { data: dataConnections, isLoading: isLoadingDataConnections } = useDataConnections()
    const { data: objects, isLoading: isLoadingObjects } = useObjects()
    const { selectedStack } = useAppContext()

    const isLoading = isLoadingDataConnections || isLoadingObjects

    if (!isEnabled) {
        return <Redirect to={Urls.Root} />
    }

    if (isLoading || !selectedStack || !dataConnections || !objects) {
        return <Spinner />
    }

    const objectsPerDataConnection: Record<string, ObjectDto[]> = {}
    dataConnections.forEach((dc) => (objectsPerDataConnection[dc._sid] = []))
    objects.forEach((obj) => {
        if (obj.data_connection && objectsPerDataConnection[obj.data_connection]) {
            objectsPerDataConnection[obj.data_connection].push(obj)
        }
    })

    const pStyle = { margin: '0.5rem 0' }

    return (
        <Box m="l">
            <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>
                Data connection support view
            </h1>
            {dataConnections?.map((dc: DataConnectionDto) => {
                return (
                    <Box mb="3xl" key={dc._sid}>
                        <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                            {dc.label} ({dc.type})
                        </h3>
                        <p style={pStyle}>
                            <b>Nango connection ID:</b> {dc.nango_connection_id}
                        </p>
                        <p style={pStyle}>
                            <b>External database ID:</b> {dc.external_database_id}
                        </p>
                        <p style={pStyle}>
                            <b>External object IDs:</b> {dc.external_object_ids}
                        </p>
                        <p style={pStyle}>
                            <b>External user email and ID:</b> {dc.external_user_email} (
                            {dc.external_user_id})
                        </p>
                        <p style={pStyle}>
                            <b>Status:</b> {dc.status}
                        </p>
                        <p style={pStyle}>
                            <b>Last sync:</b> started at {dc.last_sync_start_time ?? '?'}, completed
                            at {dc.last_sync_completed_time ?? '?'}
                        </p>
                        <p style={pStyle}>
                            <b>Objects:</b>{' '}
                            {objectsPerDataConnection[dc._sid]?.length ? '' : 'none'}
                        </p>
                        {objectsPerDataConnection[dc._sid]?.length && (
                            <ul style={{ paddingLeft: '1rem' }}>
                                {objectsPerDataConnection[dc._sid].map((obj) => (
                                    <li key={obj._sid}>
                                        {obj.name} ({obj._sid})
                                    </li>
                                ))}
                            </ul>
                        )}
                        <p style={pStyle}>
                            <b>
                                <a
                                    href={`https://app.nango.dev/${settings.IS_PROD ? 'prod' : 'dev'}/logs?connections=${encodeURIComponent(dc.nango_connection_id ?? '')}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Logs in Nango
                                </a>
                            </b>
                        </p>
                    </Box>
                )
            })}
        </Box>
    )
}
