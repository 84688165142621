import React from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'

import { Box } from 'ui/components/Box'

import { useDetailViewActionControlsState } from './hooks/useDetailViewActionControlsState'

type DetailViewActionControlsProps = {}

export const DetailViewActionControls: React.FC<DetailViewActionControlsProps> = () => {
    const { object, selectedButtons, updateConfig } = useDetailViewActionControlsState()

    if (!object) return null

    return (
        <Box
            px="l"
            style={{
                marginTop: '-8px',
            }}
        >
            <ActionsContextProvider object={object}>
                <ActionButtonsSelector
                    object={object}
                    selectedButtons={selectedButtons}
                    setConfig={updateConfig}
                    showHeader={true}
                    canDeleteActions={true}
                    canEditActions={true}
                    canSearchActions={true}
                    canReorderActions={true}
                    animate={false}
                    height="auto"
                    maxHeight="none"
                />
            </ActionsContextProvider>
        </Box>
    )
}
