import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const MAX_VISIBLE_ACTIONS = 2

type UseDetailViewTopBarActionsStateProps = {
    actions?: ActionDto[]
}

export function useDetailViewTopBarActionsState({ actions }: UseDetailViewTopBarActionsStateProps) {
    const visibleActions: ActionDto[] = actions?.slice(0, MAX_VISIBLE_ACTIONS) ?? []
    const menuActions: ActionDto[] = actions?.slice(MAX_VISIBLE_ACTIONS) ?? []

    return useDeepEqualsMemoValue({
        visibleActions,
        menuActions,
    })
}
