import { mergeAttributes } from '@tiptap/core'
import { TaskItem } from '@tiptap/extension-task-item'
import { ReactNodeViewRenderer, wrappingInputRule } from '@tiptap/react'

import { CheckItemComponent } from './CheckItemComponent'

export function createCheckItemExtension() {
    return TaskItem.extend({
        addNodeView() {
            return ReactNodeViewRenderer(CheckItemComponent, {
                as: 'li',
            })
        },
        parseHTML() {
            return [{ tag: 'check-item' }]
        },
        renderHTML({ HTMLAttributes }) {
            return ['check-item', mergeAttributes(HTMLAttributes)]
        },
        addInputRules() {
            return [
                wrappingInputRule({
                    find: inputRegexp,
                    type: this.type,
                    getAttributes: (match) => ({
                        checked: match[match.length - 1] === 'x',
                    }),
                }),
            ]
        },
    }).configure({
        nested: true,
    })
}

const inputRegexp = /^\s*(-?\[([( |x])?\])\s$/
