import React, { useCallback, useEffect, useState } from 'react'

import { useFields } from 'data/hooks/fields'
import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import {
    Widget,
    WidgetAdminControlsComponent,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Select, SelectOption } from 'ui/components/Select'
import { Body, Headline } from 'ui/components/Text'

export type FieldsWidgetType = Widget<{
    title: string
    fieldApiNames: string[]
}>

type FieldsWidgetProps = {}

export const FieldsWidget: WidgetComponent<FieldsWidgetType, FieldsWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const title = widget.attrs.title || 'Your title'
    const fieldApiNames = widget.attrs.fieldApiNames || []

    const {
        record,
        isFetchingSlow,
        saveChanges,
        discardChanges,
        replaceAttribute,
        mutateAttribute,
        clearAttribute,
        isDirty,
    } = useRecordManagerContext()
    const { data: fields = [] } = useFields({
        objectId: record?.object_id,
        options: {
            enabled: !!record?.object_id,
        },
    })

    const [isEditingField, setIsEditingField] = useState('')
    useEffect(() => {
        // Reset the editing field when we're not in editing mode.
        if (!isEditing) {
            setIsEditingField('')
        }
    }, [isEditing])

    return (
        <Box flex column gap="m">
            <Headline size="m" color="text" mb="m">
                {title}
            </Headline>
            {fieldApiNames.map((fieldApiName) => {
                const value = record?.[fieldApiName] || ''

                const field = fields.find((f) => f.api_name === fieldApiName)
                if (!field) return null

                const saveField = () => {
                    setIsEditingField('')

                    if (!isDirty) return
                    saveChanges()
                }

                const discardField = () => {
                    setIsEditingField('')
                    discardChanges()
                }

                const clearField = () => {
                    clearAttribute(fieldApiName)
                    setIsEditingField('')
                    queueMicrotask(() => {
                        saveChanges()
                    })
                }

                return (
                    <Box key={fieldApiName}>
                        <Box mb="xs">
                            <Body size="s" color="textWeaker" weight="bold">
                                {field.label}
                            </Body>
                        </Box>
                        {isEditingField === fieldApiName ? (
                            <Box flex center gap="s">
                                <Input
                                    value={value}
                                    autoFocus
                                    autoComplete="off"
                                    onBlur={saveField}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            saveField()
                                        }
                                        if (e.key === 'Escape') {
                                            e.preventDefault()
                                            discardField()
                                        }
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value

                                        // Two ways of updating the field value
                                        mutateAttribute(fieldApiName, () => {
                                            return value as any
                                        })
                                        if (field.is_primary) {
                                            replaceAttribute('_primary', value)
                                        }
                                    }}
                                    style={{
                                        maxWidth: '300px',
                                    }}
                                    endAction={({ disabled, readOnly, ...endActionProps }) => (
                                        <Button
                                            size="xs"
                                            variant="ghost"
                                            aria-label="Clear"
                                            disabled={disabled || readOnly}
                                            startIcon={{ name: 'XCircle' }}
                                            onClick={clearField}
                                            {...endActionProps}
                                        />
                                    )}
                                />
                            </Box>
                        ) : (
                            <Box flex center gap="s">
                                {value ? (
                                    <TextAttributeDisplay
                                        value={value}
                                        field={field}
                                        isLoading={isFetchingSlow}
                                    />
                                ) : (
                                    <Body
                                        size="m"
                                        color="textWeaker"
                                        style={{ fontStyle: 'italic' }}
                                    >
                                        None
                                    </Body>
                                )}
                                {!isEditing && (
                                    <Button
                                        variant="ghost"
                                        size="2xs"
                                        startIcon={{ name: 'Pencil' }}
                                        aria-label="Edit field"
                                        onClick={() => setIsEditingField(fieldApiName)}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                )
            })}
        </Box>
    )
}

type FieldsWidgetAdminControlsProps = {}

export const FieldsWidgetAdminControls: WidgetAdminControlsComponent<
    FieldsWidgetType,
    FieldsWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const title = widget.attrs.title || ''
    const fieldApiNames = widget.attrs.fieldApiNames || []

    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [onChange]
    )

    const onChangeFieldApiNames = useCallback(
        (value: string[]) => {
            onChange((attrs) => {
                attrs.set('fieldApiNames', value)
            })
        },
        [onChange]
    )

    const { view } = useLayoutEditorContext()
    const { data: fields = [] } = useFields({
        objectId: view?.object_id,
        options: {
            enabled: !!view?.object_id,
        },
    })

    return (
        <Box px="l" flex column gap="l">
            <Input label="Title" placeholder="Your title" value={title} onChange={onChangeTitle} />
            <Select
                label="Fields"
                multiSelect
                value={fieldApiNames}
                onChange={onChangeFieldApiNames}
            >
                {fields
                    .filter((f) => f.type === 'string' && !f.is_read_only)
                    .map((field) => (
                        <SelectOption
                            key={field.api_name}
                            value={field.api_name}
                            label={field.label}
                        />
                    ))}
            </Select>
        </Box>
    )
}
