import React from 'react'

import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { DetailViewHeaderEyebrowAttribute } from 'features/views/DetailView/attributes/DetailViewHeaderEyebrowAttribute'
import { DetailViewProfileImageAttribute } from 'features/views/DetailView/attributes/DetailViewProfileImageAttribute'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewSlimHeaderState } from './hooks/useDetailViewSlimHeaderState'
import { DetailViewTopBar } from './DetailViewTopBar'
import { DetailViewTopBarBreadcrumbs } from './DetailViewTopBarBreadcrumbs'

import { DetailViewSlimHeaderStyle } from './DetailViewHeader.css'

type DetailViewSlimHeaderProps = {}

export const DetailViewSlimHeader: React.FC<DetailViewSlimHeaderProps> = () => {
    const { headerRef, isPreviewing, isLoading } = useDetailViewSlimHeaderState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box
            ref={headerRef}
            as="header"
            className={DetailViewSlimHeaderStyle}
            style={{
                top: isPreviewing ? `${BANNER_HEIGHT}px` : undefined,
            }}
        >
            <DetailViewTopBar
                width="full"
                px={{
                    mobile: 'xl',
                    tablet: 0,
                }}
                pb={{
                    mobile: 's',
                    tablet: 0,
                }}
            />
            {isMobile && (
                <Box
                    flex
                    column
                    pt="xl"
                    px="xl"
                    borderTopWidth={1}
                    borderColor="dividerWeak"
                    gap="xs"
                    width="full"
                >
                    <Skeleton isLoading={isLoading}>
                        <Box flex center grow shrink gap="xs">
                            <DetailViewTopBarBreadcrumbs size="m" />
                            <Box flex center shrink grow gap="m">
                                <DetailViewProfileImageAttribute noShrink />
                                <DetailViewTitleAttribute size="m" />
                            </Box>
                        </Box>
                    </Skeleton>
                    <DetailViewHeaderEyebrowAttribute isLoading={isLoading} />
                </Box>
            )}
        </Box>
    )
}
