import { mergeAttributes, Node } from '@tiptap/core'
import { InputRule, ReactNodeViewRenderer } from '@tiptap/react'

import { getAbsoluteWorkspaceRootUrl } from 'app/UrlService'

import { RecordLinkComponent } from './RecordLinkComponent'
import { parseDetailViewInputURL, whitespaceRegexp } from './recordLinkExtensionFunctions'
// test
export type RecordLinkExtensionOptions = {
    fetchRecordsFn?: () => RecordDto[]
    fetchStacksFn?: () => StackDto[]
    fetchWorkspaceAccountFn?: () => Account | null
}

export function createRecordLinkExtension(options: RecordLinkExtensionOptions) {
    return Node.create<RecordLinkExtensionOptions>({
        name: 'recordLink',
        addOptions() {
            return {
                fetchRecordsFn: options.fetchRecordsFn,
                fetchStacksFn: options.fetchStacksFn,
                fetchWorkspaceAccountFn: options.fetchWorkspaceAccountFn,
            }
        },
        inline: true,
        group: 'inline',
        draggable: true,
        selectable: false,
        atom: true,
        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-id'),
                    renderHTML: (attributes) => ({
                        'data-id': attributes.id,
                    }),
                },
                stack_id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-stack-id'),
                    renderHTML: (attributes) => ({
                        'data-stack-id': attributes.stack_id,
                    }),
                },
                url: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-url'),
                    renderHTML: (attributes) => ({
                        'data-url': attributes.url,
                    }),
                },
            }
        },
        parseHTML() {
            return [
                {
                    tag: `record-link`,
                },
            ]
        },
        renderHTML({ HTMLAttributes }) {
            return ['record-link', mergeAttributes(HTMLAttributes)]
        },
        renderText({ node }) {
            // Make sure we have an absolute Record URL.
            const url = node.attrs.url
            if (url.startsWith('http')) return url

            const workspaceAccount = this.options.fetchWorkspaceAccountFn?.()
            const stacks = this.options.fetchStacksFn?.()
            const stack = stacks?.find((stack) => stack._sid === node.attrs.stack_id)

            if (!stack || !workspaceAccount) {
                return `${window.location.origin}${url}`
            }

            const workspaceUrl = getAbsoluteWorkspaceRootUrl(workspaceAccount)
            let relativeUrl = url
            if (!workspaceAccount.sso_required) {
                relativeUrl = url.split(workspaceAccount.slug)[1]
            }

            return `${workspaceUrl}${relativeUrl}`
        },
        addInputRules() {
            return [
                new InputRule({
                    find: (content) =>
                        parseDetailViewInputURL(
                            content,
                            this.options.fetchWorkspaceAccountFn,
                            this.options.fetchStacksFn
                        ),
                    handler: ({ state, range, match }) => {
                        if (!match[0]) return

                        const attributes = match.data
                        const { tr } = state

                        let start = range.from
                        let end = range.to

                        const matchedInput = match.input
                        const numOfQuotes = matchedInput ? matchedInput.split('`').length - 1 : 0
                        const isWithinQuotes = numOfQuotes % 2 === 1

                        // Prevent links from being added inside a code mark or an unfinished code mark.
                        const codeMark = state.schema.marks.code
                        if (
                            isWithinQuotes ||
                            !codeMark ||
                            state.doc.rangeHasMark(start, end, codeMark)
                        ) {
                            return
                        }

                        // Insert leading whitespace back in.
                        const firstChar = match[0][0]
                        if (firstChar && whitespaceRegexp.test(firstChar)) {
                            start = start + 1
                        }

                        tr.replaceWith(start, end, [
                            this.type.create(attributes),
                            // Add trailing whitespace.
                            state.schema.text(' '),
                        ])
                    },
                }),
            ]
        },
        addNodeView() {
            return ReactNodeViewRenderer(RecordLinkComponent)
        },
        addStorage() {
            return {
                records: () => [],
            }
        },
        onBeforeCreate() {
            this.storage.records = () => {
                const records: Record<
                    string,
                    {
                        id: string
                        stack_id: string
                    }
                > = {}

                this.editor.state.doc.descendants((node) => {
                    if (node.type.name === this.type.name) {
                        if (records.hasOwnProperty(node.attrs.id)) return

                        records[node.attrs.id] = {
                            id: node.attrs.id,
                            stack_id: node.attrs.stack_id,
                        }
                    }
                })

                return Object.values(records)
            }
        },
    })
}
