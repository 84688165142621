import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useObject } from 'data/hooks/objects'
import FieldPicker from 'features/studio/ui/FieldPicker'
import { getIsLinkField } from 'utils/fieldUtils'

import { Checkbox, Text } from 'v2/ui'

import { ConfigurationComponentProps } from './common'
import FiltersConfiguration from './FiltersConfiguration'
import { ConfigurationField } from './ui'

type Props = {
    name: string
    checkboxLabel: string
    value?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    controlled?: boolean
    required?: boolean
    disabled?: boolean
}

export const CheckboxSetting: React.FC<Props> = ({
    name,
    checkboxLabel,
    value,
    onChange,
    controlled,
    required,
    disabled,
}) => {
    return (
        <Checkbox
            style={{ marginTop: '8px' }}
            isDisabled={disabled}
            isChecked={value}
            onChange={onChange}
            name={name}
            isRequired={required}
            controlled={controlled}
        >
            <Text fontSize="13px">{checkboxLabel}</Text>
        </Checkbox>
    )
}

export const LookupFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({ object }) => {
    const fieldId = useWatch({ name: 'connection_options.lookup_relationship_field' })
    const { setValue } = useFormContext()
    const field = object?.fields?.find((f) => f._sid === fieldId)

    const lookupTarget = field?.link_target_object_id
    const { object: lookupTargetObj } = useObject(lookupTarget)

    function filterLinks(field: FieldDto) {
        return getIsLinkField(field) && field.synthetic_field_type !== 'lookup' && !field.is_foreign
    }

    useEffect(() => {
        setValue('connection_options.filters', [], { shouldValidate: true, shouldDirty: true })
    }, [fieldId, setValue])
    return (
        <>
            <ConfigurationField
                label="From the record linked in"
                as={FieldPicker}
                objectId={object?._sid}
                name="connection_options.lookup_relationship_field"
                placeholder="Select a field"
                filter={filterLinks}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                show={!!field}
                label="Display the"
                as={FieldPicker}
                objectId={lookupTarget}
                name="connection_options.lookup_field"
                placeholder="Select a field"
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                key={fieldId}
                show={!!field && field.type === 'multi_lookup'}
                as={FiltersConfiguration}
                name="connection_options.filters"
                checkboxLabel="Only include records that meet certain conditions"
                object={lookupTargetObj}
                controlled
                required={false}
            />
            <ConfigurationField
                show={!!field && field.type === 'multi_lookup'}
                as={CheckboxSetting}
                name="connection_options.unique_values"
                checkboxLabel="Only include unique values"
                controlled
                required={false}
            />
        </>
    )
}
