import React from 'react'

import { LayoutEditorViewPane } from 'features/views/LayoutEditor/controls/LayoutEditorViewPane'
import { LayoutEditorWidgetPicker } from 'features/views/LayoutEditor/controls/LayoutEditorWidgetPicker'

import { DetailViewHeaderControls } from './headers/DetailViewHeaderControls'
import { useDetailViewControlsState } from './hooks/useDetailViewControlsState'
import { DetailViewLayoutControls } from './layouts/DetailViewLayoutControls'
import { DetailViewActionControls } from './DetailViewActionControls'
import { DetailViewTabControl, DetailViewTabControlItem } from './DetailViewTabControl'

type DetailViewControlsProps = {}

export const DetailViewControls: React.FC<DetailViewControlsProps> = () => {
    const { title, onClickWidget } = useDetailViewControlsState()

    return (
        <LayoutEditorViewPane title={title}>
            <DetailViewTabControl>
                <DetailViewTabControlItem value="widgets" label="Widgets">
                    <LayoutEditorWidgetPicker onClickWidget={onClickWidget} />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="header" label="Header">
                    <DetailViewHeaderControls />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="style" label="Style">
                    <DetailViewLayoutControls />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="controls" label="Controls">
                    <DetailViewActionControls />
                </DetailViewTabControlItem>
            </DetailViewTabControl>
        </LayoutEditorViewPane>
    )
}
