import React, { useCallback } from 'react'

import {
    Widget,
    WidgetAdminControlsComponent,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Body, Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

export type TextWidgetType = Widget<{
    title: string
    content: string
}>

type TextWidgetProps = {}

export const TextWidget: WidgetComponent<TextWidgetType, TextWidgetProps> = ({
    widget,
    isSelected,
}) => {
    const title = widget.attrs.title
    const content = widget.attrs.content

    const hasTitle = !!title
    const hasContent = !!content

    return (
        <Box flex column gap="m">
            <Headline size="m" color={hasTitle || !isSelected ? 'text' : 'textWeakest'}>
                {title || 'Your title'}
            </Headline>
            <Body color={hasContent || !isSelected ? 'text' : 'textWeakest'}>
                {content || 'Your content'}
            </Body>
        </Box>
    )
}

type TextWidgetAdminControlsProps = {}

export const TextWidgetAdminControls: WidgetAdminControlsComponent<
    TextWidgetType,
    TextWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const title = widget.attrs.title || ''
    const content = widget.attrs.content || ''

    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [onChange]
    )

    const onChangeContent = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value

            onChange((attrs) => {
                attrs.set('content', value)
            })
        },
        [onChange]
    )

    return (
        <Box px="l" flex column gap="l">
            <Input label="Title" placeholder="Your title" value={title} onChange={onChangeTitle} />
            <Textarea
                label="Content"
                placeholder="Your content"
                fullWidth
                autoSize={false}
                resizable={false}
                value={content}
                onChange={onChangeContent}
                richText={false}
            />
        </Box>
    )
}
