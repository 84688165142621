import { LayoutEditorSchema } from 'features/views/LayoutEditor/types'
import { FieldsWidgetMeta } from 'features/views/LayoutEditor/widgets/FieldsWidgetMeta'
import { SectionWidgetMeta } from 'features/views/LayoutEditor/widgets/SectionWidgetMeta'
import { TextWidgetMeta } from 'features/views/LayoutEditor/widgets/TextWidgetMeta'

export async function computeDetailViewSchema(): Promise<LayoutEditorSchema> {
    return {
        version: 1,
        widgets: {
            text: TextWidgetMeta,
            section: SectionWidgetMeta,
            fields: FieldsWidgetMeta,
        },
    }
}
