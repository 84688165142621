import { useCallback, useMemo } from 'react'

import { getAbsoluteUrl } from 'app/UrlService'
import { canCreateRecords, canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { useActionButtons } from 'features/actions/actionHooks'
import { useCopyLink } from 'features/utils/useCopyLink'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useDetailViewTopBarQuickActionsState() {
    const {
        object,
        viewPath,
        deleteRecord: executeDeleteRecord,
        duplicateRecord: executeDuplicateRecord,
        recordManager,
        view,
    } = useDetailViewContext()

    const absoluteUrl = viewPath ? getAbsoluteUrl(viewPath) : window.location.href
    const executeCopy = useCopyLink()
    const copyLink = useCallback(async () => {
        return executeCopy(absoluteUrl)
    }, [absoluteUrl, executeCopy])
    const canCopyLink = true

    const duplicateRecord = useCallback(async () => {
        return executeDuplicateRecord()
    }, [executeDuplicateRecord])
    const canDuplicateRecord = !!object ? canCreateRecords(object) : false

    const deleteRecord = useCallback(() => {
        // Using queueMicrotask to ensure that the modal is opened
        // after the dropdown is closed, otherwise the dropdown
        // focus lock will override the modal focus lock.
        queueMicrotask(() => {
            executeDeleteRecord()
        })
    }, [executeDeleteRecord])
    const canDeleteRecord = !!object
        ? canDeleteRecords(object) && !!recordManager.record?._permissions?.may_delete
        : false

    const activeButtons = view?.layout?.actions?.pageButtons ?? []
    const actions = useActionButtons(activeButtons, recordManager.record!, object!)
    const actionsMemo = useDeepEqualsMemoValue(actions.map((a) => a.action))

    return useMemo(
        () => ({
            copyLink: canCopyLink ? copyLink : undefined,
            duplicateRecord: canDuplicateRecord ? duplicateRecord : undefined,
            deleteRecord: canDeleteRecord ? deleteRecord : undefined,
            actions: actionsMemo,
        }),
        [
            canCopyLink,
            copyLink,
            canDuplicateRecord,
            duplicateRecord,
            canDeleteRecord,
            deleteRecord,
            actionsMemo,
        ]
    )
}
