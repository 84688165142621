import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useObject } from 'data/hooks/objects'
import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'

import { Button } from 'v2/ui'

type CreateFieldPopoverProps = {
    object: ObjectDto
    onFieldCreated?: (field: any) => Promise<unknown>
    usePortal?: boolean
    buttonVariant: string
    cta: string
    className?: string
}

export const CreateFieldPopover: React.FC<CreateFieldPopoverProps> = ({
    object,
    onFieldCreated,
    buttonVariant,
    className,
    cta,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>()

    const { fetch: _fetchObjects } = useObject(object?._sid)
    const targetRef = useRef()

    const handleAddField = useCallback(
        (e): void => {
            setIsOpen(!isOpen)
            e.preventDefault()
            e.stopPropagation()
        },
        [isOpen]
    )

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const handleFieldCreated = useCallback(
        async (newField: FieldDto): Promise<void> => {
            await _fetchObjects()
            await onFieldCreated?.(newField)
            setIsOpen(false)
        },
        [_fetchObjects, onFieldCreated]
    )

    return useMemo(
        () => (
            <>
                <Button
                    ref={targetRef}
                    onClick={handleAddField}
                    style={{
                        height: 30,
                    }}
                    variant={buttonVariant}
                    p={2}
                    className={className}
                    size="sm"
                    icon="add"
                >
                    {cta}
                </Button>
                <FieldEditorPopover
                    objectId={object?._sid}
                    open={isOpen}
                    target={targetRef.current}
                    onSuccess={handleFieldCreated}
                    onCancel={onClose}
                    onClose={onClose}
                    placement="bottom-start"
                />
            </>
        ),
        [
            buttonVariant,
            className,
            cta,
            handleAddField,
            handleFieldCreated,
            isOpen,
            object?._sid,
            onClose,
        ]
    )
}
