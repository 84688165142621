import React, { useCallback } from 'react'

import { DetailViewControlSeparator } from 'features/views/DetailView/DetailViewControlSeparator'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { getYLayout } from 'features/views/LayoutEditor/utils'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { theme } from 'ui/styling/Theme.css'

type DetailViewLayoutControlsProps = {}

export const DetailViewLayoutControls: React.FC<DetailViewLayoutControlsProps> = () => {
    const { commands, view } = useLayoutEditorContext()

    const contentWidth = view?.layout?.contentWidth ?? 'full'
    const onContentWidthChange = useCallback(
        (value: string) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('contentWidth', value)
            })
        },
        [commands]
    )

    const widgetStyle = view?.layout?.widgetStyle ?? 'compact'
    const onWidgetStyleChange = useCallback(
        (value: string) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('widgetStyle', value)
            })
        },
        [commands]
    )

    return (
        <Box px="l">
            <Field label="Content width">
                <RadioCardGroup
                    value={contentWidth}
                    onValueChange={onContentWidthChange}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 136px)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard
                        value="full"
                        icon={() => <ContentWidthFullIcon isActive={contentWidth === 'full'} />}
                        cardIconWrapperProps={{
                            style: { padding: 0, overflow: 'hidden' },
                        }}
                    >
                        Full-width
                    </RadioCard>
                    <RadioCard
                        value="boxed"
                        icon={() => <ContentWidthBoxedIcon isActive={contentWidth === 'boxed'} />}
                        cardIconWrapperProps={{
                            style: { padding: 0, overflow: 'hidden' },
                        }}
                    >
                        Boxed width
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <DetailViewControlSeparator my="3xl" />
            <Select
                placeholder="Select style..."
                label="Widget style"
                value={widgetStyle}
                onChange={onWidgetStyleChange}
                disabled
            >
                <SelectOption value="compact" label="Compact" />
            </Select>
        </Box>
    )
}

type ContentWidthIconProps = {
    isActive: boolean
}

const ContentWidthFullIcon: React.FC<ContentWidthIconProps> = ({ isActive }) => {
    return (
        <Box
            style={{
                paddingTop: '7px',
                paddingBottom: '6px',
            }}
        >
            <svg
                width="40px"
                height="100%"
                viewBox="0 0 40 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="39"
                    height="26"
                    rx="1.5"
                    style={{
                        transition: theme.transition.colors,
                        stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                    }}
                />
                <rect
                    width="34"
                    height="21"
                    rx="1"
                    transform="matrix(-1 0 0 1 37 3)"
                    style={{
                        transition: theme.transition.colors,
                        fill: isActive ? theme.color.theme100 : theme.color.gray100,
                    }}
                />
            </svg>
        </Box>
    )
}

const ContentWidthBoxedIcon: React.FC<ContentWidthIconProps> = ({ isActive }) => {
    return (
        <Box
            style={{
                paddingTop: '7px',
                paddingBottom: '6px',
            }}
        >
            <svg
                width="40px"
                height="100%"
                viewBox="0 0 40 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="39"
                    height="26"
                    rx="1.5"
                    style={{
                        transition: theme.transition.colors,
                        stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                    }}
                />
                <rect
                    width="24"
                    height="21"
                    rx="1"
                    transform="matrix(-1 0 0 1 32 3)"
                    style={{
                        transition: theme.transition.colors,
                        fill: isActive ? theme.color.theme100 : theme.color.gray100,
                    }}
                />
            </svg>
        </Box>
    )
}
