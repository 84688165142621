import React from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { Box } from 'ui/components/Box'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon, IconNameVariantType } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'

type DetailViewCollapsibleControlProps = {
    label: string
    startIcon?: IconNameVariantType
    defaultOpen?: boolean
}

export const DetailViewCollapsibleControl: React.FC<DetailViewCollapsibleControlProps> = ({
    startIcon,
    label,
    children,
    defaultOpen = true,
}) => {
    const [isOpen, setIsOpen] = useControllableState({
        defaultProp: defaultOpen,
    })

    return (
        <Collapsible onOpenChange={setIsOpen} open={isOpen}>
            <CollapsibleTrigger flex center width="full">
                <Box flex center grow textAlign="left" gap="xs" px="l">
                    {startIcon && <Icon {...startIcon} size="m" color="icon" />}
                    <Body size="m" weight="bold" color="text">
                        {label}
                    </Body>
                </Box>
                <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} size="l" color="gray700" />
            </CollapsibleTrigger>
            <CollapsibleContent animation="none">
                <Box pt="l" pb="2xs" px="l">
                    {children}
                </Box>
            </CollapsibleContent>
        </Collapsible>
    )
}
