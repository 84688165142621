import React from 'react'

import { BoxProps } from '@chakra-ui/react'
import { css } from 'emotion'

import Box from './Box'

type WithBadgeProps = {
    label: string
    badgeColor?: string
    wrapperStyle?: any
} & BoxProps

const WithBadge: React.FC<WithBadgeProps> = ({
    label,
    badgeColor = 'adminBrand',
    children,
    wrapperStyle,
    ...props
}) => {
    return (
        <div style={{ position: 'relative', ...wrapperStyle }}>
            {children}
            <Box className={Lozenge} bg={badgeColor} fontSize="xs" {...props}>
                {label}
            </Box>
        </div>
    )
}

const Lozenge = css`
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-weight: bold;

    pointer-events: none;
`
export default WithBadge
