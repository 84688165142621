import { useQuery as _useQuery } from 'react-query'

import { dataConnectionApi } from 'data/api/dataConnectionApi'
import { buildQueryKey, queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'

const LIST_NAME = 'useDataConnections'
function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
function getQueryKey() {
    return buildQueryKey(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}

export function useDataConnections() {
    const queryKey = useQueryKey()

    return _useQuery<DataConnectionDto[]>(
        queryKey,
        async () => (await dataConnectionApi.get(undefined)) as DataConnectionDto[]
    )
}

export function refetchDataConnections() {
    invalidateDataConnections()
    return queryClient.refetchQueries(getQueryKey())
}

export function invalidateDataConnections() {
    return queryClient.invalidateQueries([LIST_NAME])
}
