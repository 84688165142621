import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useDroppable } from '@dnd-kit/core'
import { SortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { useBreadcrumbsContext } from 'features/breadcrumbs/hooks/useBreadcrumbsContext'
import { IsColumnOverflowingBottomStyle } from 'features/views/ListView/BoardView/BoardView.css'
import { BoardViewColumn } from 'features/views/ListView/BoardView/types'
import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useToast } from 'ui/components/Toast'

export type BoardViewCardValue = {
    record: RecordDto
    isNotMatchingFilters: boolean
}

type UseBoardViewColumnRendererStateOptions = {
    column: BoardViewColumn
    records?: RecordDto[]
}

export function useBoardViewColumnRendererState(options: UseBoardViewColumnRendererStateOptions) {
    const {
        allowReorder,
        hasFilters,
        isEmbedded,
        updateRecord,
        manualSortKey,
        sortBy,
        view,
        object,
        stack,
    } = useBoardViewContext()
    const allowReorderRef = useRef(allowReorder)
    allowReorderRef.current = allowReorder
    const viewRef = useRef(view)
    viewRef.current = view

    const { column, records } = options
    const boardColumnRef = useRef(column)
    boardColumnRef.current = column

    const [showAddNew, setShowAddNew] = useState<'top' | 'bottom' | false>(false)
    const showAddNewRef = useRef(showAddNew)
    showAddNewRef.current = showAddNew

    const onAddNewCancel = useCallback(() => {
        setShowAddNew(false)
    }, [])

    const onAddNewToggle = useCallback((position: 'top' | 'bottom' = 'bottom') => {
        setShowAddNew(position)
    }, [])

    const [temporaryRecords, setTemporaryRecords] = useState<RecordDto[]>([])
    useEffect(() => {
        // Clear temporary records when filters are removed.
        if (!hasFilters) setTemporaryRecords([])
    }, [hasFilters])

    const removeRecordNotMatchingFilters = useCallback((recordSid: string) => {
        setTemporaryRecords((prev) => prev.filter((record) => record._sid !== recordSid))
    }, [])

    const hasFiltersRef = useRef(hasFilters)
    hasFiltersRef.current = hasFilters

    const firstRecordSid = records?.[0]?._sid
    const firstRecordLocalDisplayOrder = records?.[0]?._local_display_order ?? 0

    const toast = useToast()

    const { navigateTo } = useBreadcrumbsContext()

    const onAddNewRecord = useCallback(
        (newRecord: RecordDto, isFirstInColumn: boolean) => {
            const newRecordTitle = newRecord._primary ?? 'Record'

            toast({
                type: 'success',
                title: `${newRecordTitle} was created successfully`,
                helperText: 'Click to open record',
                showDismissButton: true,
                startIcon: {
                    name: 'CheckCircle2',
                },
                onClick: () => {
                    navigateTo({
                        type: 'detail',
                        recordSid: newRecord._sid,
                        object: object!,
                        openAs: 'full',
                        stack,
                    })
                },
            })

            const allowReorder = allowReorderRef.current
            if (allowReorder && isFirstInColumn) {
                // If this should be the first record in the column,
                // we need to update its display order.
                updateRecord(newRecord, {
                    _display_order_key: manualSortKey,
                    _local_display_order: firstRecordLocalDisplayOrder - 1,
                    _display_order_by: sortBy ? `${sortBy.desc ? '-' : ''}${sortBy.id}` : undefined,
                    _display_order_before: firstRecordSid,
                })
            }

            const hasAnyFilters =
                hasFiltersRef.current || (view.options.filters && view.options.filters.length > 0)

            if (!hasAnyFilters) return

            setTemporaryRecords((prev) => [...prev, newRecord])
        },
        [
            firstRecordLocalDisplayOrder,
            firstRecordSid,
            manualSortKey,
            navigateTo,
            object,
            sortBy,
            stack,
            toast,
            updateRecord,
            view.options.filters,
        ]
    )

    const cardValues = useMemo(() => {
        const existingRecords = records ?? []
        const existingRecordSids = new Set(existingRecords.map((record) => record._sid))

        const values: BoardViewCardValue[] = existingRecords.map((r) => ({
            record: r,
            isNotMatchingFilters: false,
        }))

        // Also show temporary records that don't match the filters.
        for (const record of temporaryRecords) {
            if (!existingRecordSids.has(record._sid)) {
                values.push({
                    record,
                    isNotMatchingFilters: true,
                })
            }
        }

        return values
    }, [records, temporaryRecords])

    const cardValuesMemo = useDeepEqualsMemoValue(cardValues)
    const valueCount = cardValuesMemo.length

    const recordsForColumnMemo = useDeepEqualsMemoValue(records ?? [])
    const recordSids = useMemo(() => {
        return recordsForColumnMemo.map((record) => record._sid)
    }, [recordsForColumnMemo])

    const { setNodeRef: columnRef, over } = useDroppable({
        id: column.id,
        data: {
            type: 'column',
            ...column,
        },
    })

    let overColumnId: string | undefined
    if (over?.data.current?.type === 'column') {
        // We are dragging over an empty column.
        overColumnId = over.data.current.id
    } else {
        // We are dragging over a record in a column.
        overColumnId = over?.data.current?.sortable?.containerId
    }

    const isDraggingOver = column.id === overColumnId

    const sortStrategy: SortingStrategy = useCallback((props) => {
        // Prevent re-ordering if we're using server-side sorting.
        const allowReorder = allowReorderRef.current
        if (!allowReorder) return null

        return verticalListSortingStrategy(props)
    }, [])

    const { targetRef, scrollAreaRef, checkOverflow } = useOverflowY({
        bottomClassName: IsColumnOverflowingBottomStyle,
    })
    const composedRef = useComposedRefs(targetRef, columnRef)

    // Check overflow when the record count changes.
    useLayoutEffect(() => {
        checkOverflow()
    }, [checkOverflow, valueCount])

    const [isCollapsed, setIsCollapsed] = useState(false)
    const onCollapseColumnClick = useCallback(() => {
        setIsCollapsed((prev) => {
            const newState = !prev

            const view = viewRef.current
            const column = boardColumnRef.current
            persistCollapsedState(view, column, !isCollapsed)

            return newState
        })
    }, [isCollapsed])

    // Restore the collapsed state from local storage.
    useLayoutEffect(() => {
        const view = viewRef.current
        const column = boardColumnRef.current

        const persistedCollapsedState = restoreCollapsedState(view, column)
        setIsCollapsed(persistedCollapsedState)
    }, [])

    return useDeepEqualsMemoValue({
        values: cardValuesMemo,
        valueCount,
        columnRef: composedRef,
        scrollAreaRef,
        records: recordsForColumnMemo,
        recordSids,
        sortStrategy,
        isDraggingOver,
        showAddNew,
        onAddNewCancel,
        onAddNewToggle,
        onAddNewRecord,
        isEmbedded,
        onCollapseColumnClick,
        isCollapsed,
        removeRecordNotMatchingFilters,
    })
}

function getCollapsedStateStorageKey(view: ViewDto, column: BoardViewColumn) {
    return `BoardViewColumnCollapsedState_${view.stack_id}_${view._sid}_${column.id}`
}

function persistCollapsedState(view: ViewDto, column: BoardViewColumn, state: boolean) {
    const key = getCollapsedStateStorageKey(view, column)
    if (state) {
        localStorage.setItem(key, state.toString())
    } else {
        localStorage.removeItem(key)
    }
}

function restoreCollapsedState(view: ViewDto, column: BoardViewColumn) {
    const key = getCollapsedStateStorageKey(view, column)
    const value = localStorage.getItem(key)

    return value?.toLowerCase() === 'true'
}
