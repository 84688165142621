import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { FieldsWidget, FieldsWidgetAdminControls, FieldsWidgetType } from './FieldsWidget'

export const FieldsWidgetMeta: WidgetSchema<FieldsWidgetType> = {
    category: 'test',
    label: 'Fields',
    icon: 'List',
    component: FieldsWidget,
    adminControlsComponent: FieldsWidgetAdminControls,
    defaultAttrs: {
        fieldApiNames: [],
    },
}
