import moment from 'moment-timezone'

import useStableState from './useStableState'

export const useIsSupportLoginPermitted = (): boolean => {
    const [isUserSupportLoginPermitted] = useStableState('permitSupportLogin')
    return isUserSupportLoginPermitted
}

export const isSupportLoginPermitted = (user: AuthedUserDto): boolean => {
    const options = user?.options || {}
    return options.support_login_expiration_date
        ? options.permit_support_login && moment(options.support_login_expiration_date).isAfter()
        : options.permit_support_login
}

export const useIsCurrentUserStackerSupport = (): boolean => {
    return [true, 'true'].includes(localStorage.getItem('support_login') as any)
}
