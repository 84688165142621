import { useMutation, UseMutationOptions } from 'react-query'

import { reloadMetadataAfterObjectChange } from 'data/hooks/refreshData'
import { fetchAndReturn } from 'data/utils/utils'

import { useToast } from 'ui/components/Toast'

export type DataConnectionDtoPatch = Omit<Partial<DataConnectionDto>, '_sid' | '_object_id'>

export function useCreateDataConnection(
    options: UseMutationOptions<DataConnectionDtoPatch, unknown, DataConnectionDtoPatch> = {}
) {
    const toast = useToast()

    return useMutation(
        async (dataConnection: DataConnectionDtoPatch) => {
            return fetchAndReturn('data-connections/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(dataConnection),
            }) as Promise<DataConnectionDtoPatch>
        },
        {
            ...options,
            onError: async (error: any) => {
                try {
                    const errText = await error.text()
                    const errResponse = JSON.parse(errText)

                    if (errResponse.exception == 'TooManyWebhooksException') {
                        toast({
                            title: 'Connection limit reached',
                            helperText:
                                'This base already has the maximum of 2 webhooks set up. Please contact support to learn more about this limitation.',
                            type: 'error',
                        })
                        return
                    }
                } catch {}

                toast({
                    title: 'There was a problem creating the data connection. Please try again later.',
                    type: 'error',
                })
            },

            onSettled: () => {
                reloadMetadataAfterObjectChange()
            },
        }
    )
}
