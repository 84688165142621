import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Location } from 'history'

import { useAppContext } from 'app/useAppContext'
import { useNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { BreadcrumbsHistoryItem, BreadcrumbsItem } from 'features/breadcrumbs/types'
import { NavigationAppMenuItem } from 'features/navigation/types'
import { mapNavTreeToAppNavigationMenuItems } from 'features/navigation/utils'
import { PathContext } from 'features/utils/PathContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useBreadcrumbs(): BreadcrumbsItem[] {
    const { selectedStack } = useAppContext()

    const history = useHistory<BreadcrumbsHistoryItem>()

    const navTree = useNavTree({ stackId: selectedStack?._sid })
    const menuItems = useDeepEqualsMemoValue(
        selectedStack ? mapNavTreeToAppNavigationMenuItems(navTree, undefined, selectedStack) : []
    )
    const { view } = useContext(PathContext)

    let breadcrumbs: BreadcrumbsItem[] = []
    switch (view?.type) {
        case 'list':
            breadcrumbs = getListBreadcrumbs(menuItems, view.object_id, history.location.pathname)
            break
        case 'detail':
            breadcrumbs = getDetailBreadcrumbs(menuItems, history.location)
            break
    }

    return useDeepEqualsMemoValue(breadcrumbs)
}

function getListBreadcrumbs(
    menuItems: NavigationAppMenuItem[],
    objectSid: string,
    currentPath: string
): BreadcrumbsItem[] {
    const parentItem = menuItems.find((item) => item.objectSid === objectSid)
    if (!parentItem) return []

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            path: parentItem.url,
            label: parentItem.name,
            type: 'list',
        },
    ]

    // Only show parent if there are multiple children.
    if (parentItem.children.length > 0) {
        const currentItem =
            parentItem.children.find((item) => item.url === currentPath) ?? parentItem.children[0]
        if (currentItem) {
            breadcrumbs.push({
                path: currentItem.url,
                label: currentItem.name,
                type: 'list',
            })
        }
    }

    return breadcrumbs
}

function getDetailBreadcrumbs(
    menuItems: NavigationAppMenuItem[],
    location: Location<BreadcrumbsHistoryItem>
): BreadcrumbsItem[] {
    const objectSid = location.state?.objectId || ''

    // If the previous state is a list, we need to show it, otherwise we show the first list item.
    const parentListPath =
        location.state?.prev?.state?.type === 'list' ? location.state?.prev?.pathname : ''
    const listBreadcrumbs = getListBreadcrumbs(menuItems, objectSid, parentListPath)

    const path = location.pathname
    const label = location.state?.title || 'Detail'

    return [
        ...listBreadcrumbs,
        {
            path,
            label,
            type: 'detail',
        },
    ]
}
