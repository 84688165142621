import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewCompactLayoutState() {
    const { contentWidth } = useDetailViewContext()

    return useMemo(
        () => ({
            contentWidth,
        }),
        [contentWidth]
    )
}
