import React, { useCallback } from 'react'

import { isEqual } from 'lodash'

import Gallery from 'v2/ui/components/Gallery'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { LinkButton } from 'ui/components/LinkButton'
import { Popup, PopupContent, PopupInner, PopupTrigger } from 'ui/components/Popup'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAttachmentsAttributeTagState } from './hooks/useAttachmentsAttributeTagState'
import { Thumbnail } from './Thumbnail'
import { AttachmentValue, AttributeDisplayComponent } from './types'

const sizeMapping = {
    button: {
        s: '2xs',
        m: 'xs',
        l: 's',
    },
    tag: {
        s: '2xs',
        m: 'xs',
        l: 's',
    },
} as const

type AttachmentsAttributeTagProps = React.ComponentPropsWithoutRef<typeof Box> & {
    size?: ResponsiveValue<'s' | 'm' | 'l'>
    displayAsImage?: boolean
    imageAltText?: string
    maxItemLength?: number
    isLoading?: boolean
    showIcon?: boolean
}

export const AttachmentsAttributeTag: AttributeDisplayComponent<
    AttachmentValue[],
    AttachmentsAttributeTagProps
> = React.memo(function AttachmentsAttributeTag({
    field,
    value,
    maxItemLength,
    displayAsImage,
    imageAltText,
    isLoading: providedIsLoading,
    size = 'm',
    showIcon,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const buttonSize = sizeMapping.button[effectiveSize]
    const tagSize = sizeMapping.tag[effectiveSize]

    const {
        attachments,
        isLoading,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
        onOverflowLabelClick,
        wrapperRef,
        isSingle,
        label,
        icon,
    } = useAttachmentsAttributeTagState({
        field,
        value,
        maxItemLength,
        isLoading: providedIsLoading,
        showIcon,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
    }, [])

    if (!attachments?.length) {
        return null
    }

    return (
        <Box ref={wrapperRef} flex center {...props}>
            <Gallery
                center
                display="flex"
                grow
                useThumbnailsFromChildren={false}
                height="full"
                selector="a"
                style={{
                    maxWidth: '100%',
                    minWidth: 0,
                }}
            >
                {isSingle && (
                    <Skeleton isLoading={isLoading}>
                        <LinkButton
                            variant="secondary"
                            startIcon={icon ?? { name: 'Paperclip' }}
                            size={buttonSize}
                            href={attachments[0].url}
                            onClick={handleClick}
                            noShrink
                            {...attachments[0].attributes}
                            style={{
                                maxWidth: '100%',
                                minWidth: 0,
                            }}
                        >
                            <Box trim maxWidth="full" py="3xs">
                                {attachments[0].name}
                            </Box>
                        </LinkButton>
                    </Skeleton>
                )}

                {/* Render the gallery elements. */}
                {!isSingle &&
                    attachments.map((attachment) => (
                        <a
                            key={attachment.url}
                            href={attachment.url}
                            onClick={handleClick}
                            style={{
                                // Hide the element, but still keep it in the gallery.
                                display: 'none',
                            }}
                            {...attachment.attributes}
                        />
                    ))}

                {!isSingle && displayAsImage && (
                    <Skeleton isLoading={isLoading}>
                        <Popup open={isOverflowPopupOpen} onOpenChange={onOverflowPopupOpenChange}>
                            <PopupTrigger
                                asChild
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onFocus={onOverflowLabelFocus}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                onBlur={onOverflowLabelBlur}
                            >
                                <Tag
                                    startIcon={icon ?? { name: 'Paperclip' }}
                                    type="outline"
                                    size={tagSize}
                                    noShrink
                                    onClick={stopPropagation}
                                >
                                    {label}
                                </Tag>
                            </PopupTrigger>
                            <PopupContent
                                sideOffset={0}
                                onOpenAutoFocus={stopPropagation}
                                onCloseAutoFocus={stopPropagation}
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                side="bottom"
                                align="end"
                                style={{
                                    width: 'auto',
                                    maxWidth: '300px',
                                }}
                            >
                                <PopupInner flex center gap="xs" wrap>
                                    {attachments.map((attachment) => (
                                        <Thumbnail
                                            key={attachment.url}
                                            as="a"
                                            size="m"
                                            href={attachment.url}
                                            src={attachment.url}
                                            alt={imageAltText}
                                            maxWidth="full"
                                            maxHeight="full"
                                            onClick={onOverflowLabelClick}
                                            target="_blank"
                                            noShrink
                                            {...attachment.attributes}
                                        />
                                    ))}
                                </PopupInner>
                            </PopupContent>
                        </Popup>
                    </Skeleton>
                )}

                {!isSingle && !displayAsImage && (
                    <Skeleton isLoading={isLoading}>
                        <Dropdown
                            open={isOverflowPopupOpen}
                            onOpenChange={onOverflowPopupOpenChange}
                        >
                            <DropdownButton
                                variant="secondary"
                                size={buttonSize}
                                noShrink
                                onClick={stopPropagation}
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                startIcon={icon ?? { name: 'Paperclip' }}
                                style={{
                                    maxWidth: '100%',
                                    minWidth: 0,
                                }}
                            >
                                <Box trim maxWidth="full" py="3xs">
                                    {label}
                                </Box>
                            </DropdownButton>
                            <DropdownContent
                                sideOffset={0}
                                side="bottom"
                                align="end"
                                onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onMouseLeave={onOverflowLabelMouseLeave}
                            >
                                {attachments.map((attachment) => (
                                    <DropdownItem
                                        key={attachment.url}
                                        label={attachment.name}
                                        onClick={onOverflowLabelClick}
                                        {...attachment.attributes}
                                    />
                                ))}
                            </DropdownContent>
                        </Dropdown>
                    </Skeleton>
                )}
            </Gallery>
        </Box>
    )
}, isEqual)
