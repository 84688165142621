import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

type RepairWebhookResponse = {
    success: boolean
}

type useRepairWebhooksInput = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
}
export function useRepairWebhooks(
    options: UseMutationOptions<RepairWebhookResponse, unknown, useRepairWebhooksInput> = {}
) {
    return useMutation(async ({ nangoConnectionId, integrationId }: useRepairWebhooksInput) => {
        return fetchAndReturn('data-connections/webhooks/repair/', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                nango_connection_id: nangoConnectionId,
                integration_id: integrationId,
            }),
        }) as Promise<RepairWebhookResponse>
    }, options)
}
