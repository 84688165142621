import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { throttle } from 'lodash'
import { PreviewRecordListItem } from 'v2/views/List/PreviewRecord/PreviewRecordContext'

import { getUrl } from 'app/UrlService'
import { useBreadcrumbsContext } from 'features/breadcrumbs/hooks/useBreadcrumbsContext'
import { ActionContextMenuHandle } from 'features/views/ListView/Actions/ActionContextMenu'
import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import {
    isSystemAction,
    useSystemActions,
} from 'features/views/ListView/Actions/hooks/useSystemActions'
import { getRecordActionDisplay } from 'features/views/ListView/Actions/utils'
import { useCardViewContext } from 'features/views/ListView/CardView/useCardViewContext'
import { extractSrcFromCoverImageValue } from 'features/views/ListView/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useCardViewFooterState } from './useCardViewFooterState'
import { useCardViewTitleState } from './useCardViewTitleState'

const COVER_IMAGE_MIN_WIDTH = 150
const COVER_IMAGE_MAX_WIDTH_PERCENT = 50
const COVER_IMAGE_ASPECT_RATIO = 16 / 9

type UseCardViewCardStateOptions = {
    record?: RecordDto
    siblingRecords?: RecordDto[]
}

type LeftContentType = 'none' | 'cover' | 'profile'

export function useCardViewCardState(options: UseCardViewCardStateOptions) {
    const { record, siblingRecords } = options

    const {
        object,
        stack,
        labelStyle,
        pendingRecords,
        view,
        requestIncludedFields,
        allFields,
        coverImageField,
        profileImageField,
        cardSize,
        cardStyle,
        onRecordClick,
        titleField,
        eyebrowField,
        subtitleField,
    } = useCardViewContext()

    const recordDetailUrl = useMemo(() => {
        return getUrl(`${object?.url}/view/${record?._sid}`, stack)
    }, [object?.url, record?._sid, stack])
    const to = record ? recordDetailUrl : ''

    const { navigateTo } = useBreadcrumbsContext()

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            // Only open side peek on regular left click.
            if (e.ctrlKey || e.metaKey || e.button === 1 || !record?._sid) return

            e.preventDefault()

            // Check for any record click overrides.
            switch (onRecordClick) {
                case 'none':
                    return
                case 'new_tab':
                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'tab',
                        stack,
                    })
                    return
                case 'detail':
                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'full',
                        stack,
                    })
                    return
                case 'preview': {
                    const recordListItems: PreviewRecordListItem[] | undefined =
                        siblingRecords?.map((r) => ({
                            recordId: r._sid,
                        }))

                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'preview',
                        stack,
                        previewParams: {
                            partOfRecordList: recordListItems
                                ? {
                                      direction: 'vertical',
                                      items: recordListItems,
                                  }
                                : undefined,
                        },
                    })
                }
            }
        },
        [navigateTo, object, onRecordClick, record?._sid, siblingRecords, stack]
    )

    const { titleAttribute, contentAttributes, recordTitle } = useCardViewTitleState({ record })

    const allActions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: true,
        filterByActionDisplay: false,
    })
    const actionDisplay = getRecordActionDisplay(view)

    const systemActions = useSystemActions()

    const actionsMemo = useDeepEqualsMemoValue(
        actionDisplay ? allActions.map((a) => a.action) : systemActions
    )
    const actionsRef = useRef(actionsMemo)
    actionsRef.current = actionsMemo

    const userActions = useMemo(() => {
        return allActions.reduce((acc, a) => {
            if (!isSystemAction(a.action._sid)) {
                acc.push(a.action)
            }

            return acc
        }, [] as ActionDto[])
    }, [allActions])

    const { footerLeftButton, footerRightButton, footerLeftAttribute, footerRightAttribute } =
        useCardViewFooterState({
            cardFooter: view.options.cardFooter,
            fields: allFields,
            actions: userActions,
        })

    const cardRef = useRef<HTMLDivElement>(null)

    const actionContextMenuRef = useRef<ActionContextMenuHandle>(null)

    const onContextMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const card = cardRef.current
        if (!card) return

        // Don't open the context menu if there are no actions enabled.
        const actions = actionsRef.current
        if (!actions.length) return

        const target = e.target as HTMLElement
        const closestInteractive = target.closest('a') as HTMLElement | null
        if (closestInteractive && closestInteractive !== card) {
            // Don't open the context menu if the click was on a link.
            return
        }

        e.preventDefault()
        e.stopPropagation()

        actionContextMenuRef?.current?.openAt(e.clientX, e.clientY)
    }, [])

    const additionalEditFields = useMemo(() => {
        const fields = new Map<string, FieldDto>()

        if (titleField) {
            fields.set(titleField._sid, titleField)
        }

        if (footerLeftAttribute && typeof footerLeftAttribute !== 'string') {
            const field = footerLeftAttribute.field
            fields.set(field._sid, field)
        }

        if (footerRightAttribute && typeof footerRightAttribute !== 'string') {
            const field = footerRightAttribute.field
            fields.set(field._sid, field)
        }

        if (eyebrowField) {
            fields.set(eyebrowField._sid, eyebrowField)
        }

        if (subtitleField) {
            fields.set(subtitleField._sid, subtitleField)
        }

        return Array.from(fields.values())
    }, [titleField, eyebrowField, footerLeftAttribute, footerRightAttribute, subtitleField])

    const isPending = record && pendingRecords.some((r) => r._sid === record._sid)

    const coverImageSrc = extractSrcFromCoverImageValue(record, coverImageField)

    const leftContentType: LeftContentType = !!coverImageField
        ? 'cover'
        : !!profileImageField
          ? 'profile'
          : 'none'

    const innerRef = useRef<HTMLDivElement>(null)

    const [isFullWidth, setIsFullWidth] = useState(false)

    useLayoutEffect(() => {
        const element = innerRef.current
        if (!element) return

        const resizeObserver = new ResizeObserver(() => {
            const computedStyle = window.getComputedStyle(element)

            setIsFullWidth(computedStyle.flexDirection === 'row')
        })
        resizeObserver.observe(element)

        return () => {
            resizeObserver.disconnect()
        }
    }, [])

    const coverWrapperRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (!isFullWidth) return

        const content = contentRef.current
        const coverWrapper = coverWrapperRef.current
        const inner = innerRef.current
        if (!content || !coverWrapper || !inner) return

        const handleResize = () => {
            const minHeight = COVER_IMAGE_MIN_WIDTH / COVER_IMAGE_ASPECT_RATIO
            coverWrapper.style.height = `${minHeight}px`

            requestAnimationFrame(() => {
                const contentHeight = content.clientHeight
                const innerWidth = inner.clientWidth

                const maxWidth = innerWidth * (COVER_IMAGE_MAX_WIDTH_PERCENT / 100)
                // The width that we want to set the cover image to, if we want to maintain the aspect ratio.
                let targetWidth = contentHeight * COVER_IMAGE_ASPECT_RATIO
                // Clamp the width to the maximum and minimum values.
                targetWidth = Math.max(COVER_IMAGE_MIN_WIDTH, Math.min(maxWidth, targetWidth))

                // We have minimum and maximum limits for width, so we have to re-calculate the height based on the clamped width.
                const targetHeight = Math.floor(targetWidth / COVER_IMAGE_ASPECT_RATIO)

                coverWrapper.style.height = `${targetHeight}px`
            })
        }

        const resizeObserver = new ResizeObserver(() => {
            handleResize()
        })
        resizeObserver.observe(content)

        const mutationObserver = new MutationObserver(() => {
            handleResize()
        })
        mutationObserver.observe(content, { childList: true, subtree: true })

        const throttledHandleResize = throttle(handleResize, 100)
        window.addEventListener('resize', throttledHandleResize)

        return () => {
            coverWrapper.style.height = ''

            resizeObserver.disconnect()
            mutationObserver.disconnect()
            window.removeEventListener('resize', throttledHandleResize)
        }
    }, [isFullWidth, leftContentType])

    const placeholderAttribute = titleAttribute

    const placeholderValue =
        titleAttribute && record ? record[titleAttribute.field.api_name] : undefined

    return useMemo(
        () => ({
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            labelStyle,
            cardRef,
            onContextMenu,
            additionalEditFields,
            actionContextMenuRef,
            isPending,
            actionButtons: actionsMemo,
            includeFields: requestIncludedFields,
            footerLeftButton,
            footerRightButton,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
            coverImageField,
            profileImageField,
            cardSize,
            cardStyle,
            leftContentType,
            recordTitle,
            isFullWidth,
            coverWrapperRef,
            innerRef,
            contentRef,
            placeholderAttribute,
            placeholderValue,
        }),
        [
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            labelStyle,
            onContextMenu,
            additionalEditFields,
            isPending,
            actionsMemo,
            requestIncludedFields,
            footerLeftButton,
            footerRightButton,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
            coverImageField,
            profileImageField,
            cardSize,
            cardStyle,
            leftContentType,
            recordTitle,
            isFullWidth,
            placeholderAttribute,
            placeholderValue,
        ]
    )
}
