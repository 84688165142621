import { useCallback } from 'react'

import { useObject } from 'data/hooks/objects'
import { toYType } from 'features/utils/useYjsState'
import { getYActions } from 'features/views/DetailView/utils'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useDetailViewActionControlsState() {
    const { view, commands } = useLayoutEditorContext()

    const { object } = useObject(view?.object_id)

    const selectedButtons: ActionButton[] = useDeepEqualsMemoValue(
        view?.layout?.actions?.pageButtons || []
    )

    const updateConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            commands.transaction((data) => {
                const actions = getYActions(data)
                actions?.set('pageButtons', toYType(pageButtons))
            })
        },
        [commands]
    )

    return {
        object,
        selectedButtons,
        updateConfig,
    }
}
