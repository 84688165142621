import { useFields } from 'data/hooks/fields'
import { filterSupportedFields, getDetailViewHeader } from 'features/views/DetailView/utils'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useDetailViewHeaderControlsState() {
    const { view } = useLayoutEditorContext()

    const { data: fields = [] } = useFields({ objectId: view?.object_id })

    const supportedFields = filterSupportedFields(fields)
    const header = getDetailViewHeader(view?.layout, supportedFields)

    return {
        header,
        fields: supportedFields,
    }
}
