import React from 'react'

import { useExternalObjects } from 'data/hooks/dataConnections/useExternalObjects'
import { TRANSLATIONS } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { Link } from 'ui/components/Link'
import { Select } from 'ui/components/Select'
import { Skeleton } from 'ui/components/Skeleton'
import { Body, Headline } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'
import { Tooltip } from 'ui/components/Tooltip'

type ExternalObjectSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    nangoConnectionId: string
    externalDatabaseId: string
    selectedExternalObjectIds: Set<string>
    forcedObjectIds?: Set<string>
    setSelectedExternalObjectIds: (
        update: Set<string> | ((prevSelectedObjects: Set<string>) => Set<string>)
    ) => void
}
export const ExternalObjectSelect: React.FC<ExternalObjectSelectProps> = ({
    externalIntegrationId,
    nangoConnectionId,
    externalDatabaseId,
    selectedExternalObjectIds,
    setSelectedExternalObjectIds,
    forcedObjectIds,
}) => {
    forcedObjectIds = forcedObjectIds ?? new Set()
    const { data: externalObjects, isLoading } = useExternalObjects(
        { integrationId: externalIntegrationId, nangoConnectionId, externalDatabaseId },
        {
            enabled: !!nangoConnectionId && !!externalIntegrationId && !!externalDatabaseId,
            // if a different database is selected we want to go into a loading state and stop showing stale data immediately
            keepPreviousData: false,
        }
    )

    const handleToggleChange = (extObjectId: string) => {
        setSelectedExternalObjectIds((prevSelectedExtObjs) => {
            const newSelectedExtObjs = new Set(prevSelectedExtObjs)
            if (newSelectedExtObjs.has(extObjectId)) {
                newSelectedExtObjs.delete(extObjectId)
            } else {
                newSelectedExtObjs.add(extObjectId)
            }
            return newSelectedExtObjs.union(forcedObjectIds)
        })
    }

    const selectAll = () => {
        setSelectedExternalObjectIds(new Set(externalObjects?.map((obj) => obj.id)))
    }

    const deselectAll = () => {
        setSelectedExternalObjectIds(new Set())
    }

    return (
        <Box pt="3xl" flex flexDirection="column">
            <Box flex justifyContent="space-between">
                <Headline size="xs">
                    Choose {TRANSLATIONS[externalIntegrationId].tables} to include
                </Headline>
                <Box>
                    <Link onClick={selectAll} size="s">
                        select all
                    </Link>{' '}
                    /{' '}
                    <Link onClick={deselectAll} size="s">
                        deselect all
                    </Link>
                </Box>
            </Box>
            <Body size="m" pt="m" pb="xl">
                Select the {TRANSLATIONS[externalIntegrationId].tables} you want to sync
            </Body>
            {isLoading ? (
                <>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                </>
            ) : (
                <Box style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
                    {externalObjects?.map((extObject) => (
                        <Tooltip
                            key={extObject.id}
                            content={
                                forcedObjectIds.has(extObject.id)
                                    ? 'This table is already synced with your app'
                                    : ''
                            }
                            side="bottom"
                            zIndex={forcedObjectIds.has(extObject.id) ? 10000 : undefined}
                        >
                            <Box pt="m">
                                <Toggle
                                    checked={
                                        selectedExternalObjectIds.has(extObject.id) ||
                                        forcedObjectIds.has(extObject.id)
                                    }
                                    onCheckedChange={() => handleToggleChange(extObject.id)}
                                    disabled={forcedObjectIds.has(extObject.id)}
                                >
                                    {extObject.name}
                                </Toggle>
                            </Box>
                        </Tooltip>
                    ))}
                </Box>
            )}
        </Box>
    )
}
