import React from 'react'

import type { EditableElementWrapperProps } from 'features/admin/edit-mode/Common/types'

export type EnabledFieldConfigOptions = ListViewColumnConfigDisplayOptions & { label: boolean }

type EditableFieldWrapperBaseProps = EditableElementWrapperProps & {
    fieldId?: string
    objectId?: string
    fieldViewConfig: ListViewColumnConfig
    enabledOptions?: EnabledFieldConfigOptions
    onChange: (patch: any) => void
    onHideField: () => void
    display?: string
}

const EditableFieldWrapperBaseUnwrapped: React.FC<EditableFieldWrapperBaseProps> = ({
    children,
}) => {
    return <>{children}</>
}

export const EditableFieldWrapperBase = React.memo(EditableFieldWrapperBaseUnwrapped)
