import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewTitleAttributeState() {
    const { fields, header, recordManager } = useDetailViewContext()

    const titleFieldApiName = header.title?.fieldApiName
    const titleField = fields.find((field) => field.api_name === titleFieldApiName)

    const value = (recordManager.record?.[titleField?.api_name!] as unknown) ?? ('' as unknown)

    return useMemo(
        () => ({
            field: titleField,
            value,
        }),
        [titleField, value]
    )
}
