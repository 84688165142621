import { WebhookInfo, WebhooksInfo } from 'data/hooks/dataConnections/useGetWebhooks'

type SyncState = {
    hasWebhookError: boolean
    earliestWebhookStatus: WebhookInfo['status']
}

/**
 * Calculate the webhook state for given data connections.
 * More than one data connection can be provided, in which case the aggregate webhook state is returned
 *
 * @param webhooks Array of WebhookInfo to calculate info for
 * @returns Object containing whether aggregate webhook info
 */
export const calculateWebhookState = (webhooks: WebhooksInfo): SyncState => {
    // when aggregate the status of webhooks, the status with lower number is considered as more important to display
    // as overall status
    const STATUS_PRIORITY: Record<WebhookInfo['status'], number> = {
        WEBHOOK_NOT_SET: 1,
        WEBHOOK_NO_LONGER_EXISTS: 2,
        WEBHOOK_DISABLED: 3,
        WEBHOOK_NOTIFICATIONS_DISABLED: 4,
        VALID: 5,
    }

    let earliestWebhookStatus: WebhookInfo['status'] = 'VALID'

    for (const webhook of webhooks) {
        if (STATUS_PRIORITY[webhook.status] < STATUS_PRIORITY[earliestWebhookStatus]) {
            earliestWebhookStatus = webhook.status
        }
    }

    return {
        hasWebhookError: earliestWebhookStatus !== 'VALID',
        earliestWebhookStatus,
    }
}
