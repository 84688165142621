import React from 'react'

import { useDetailViewLayoutState } from './hooks/useDetailViewLayoutState'
import { DetailViewCompactLayout } from './DetailViewCompactLayout'

type DetailViewLayoutProps = {}

export const DetailViewLayout: React.FC<DetailViewLayoutProps> = () => {
    const { layoutType } = useDetailViewLayoutState()

    switch (layoutType) {
        case 'compact':
            return <DetailViewCompactLayout />
        default:
            return null
    }
}
