import React, { useContext } from 'react'

import { BreadcrumbsItem, BreadcrumbsRoute } from 'features/breadcrumbs/types'

export type BreadcrumbsContextValue = {
    breadcrumbs: BreadcrumbsItem[]
    navigateTo: (route: BreadcrumbsRoute) => void
    prevItem?: BreadcrumbsItem
}

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextValue | null>(null)

export function useBreadcrumbsContext(): BreadcrumbsContextValue {
    const context = useContext(BreadcrumbsContext)

    if (!context) {
        throw new Error('useBreadcrumbsContext must be used within a BreadcrumbsContextProvider')
    }

    return context
}
