import React, { FC, useMemo } from 'react'

import { BoxProps, useTheme } from '@chakra-ui/react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import Box from './Box'
import ConditionalWrapper from './ConditionalWrapper'
import { getFaIconName } from './getFaIconName'
import Tooltip, { ToolipProps } from './Tooltip'

const FaIcon: FC<
    Omit<FontAwesomeIconProps, 'icon' | 'iconPack' | 'fill'> & {
        icon: string
        iconPack?: string
        fill?: IconProps['fill']
    }
> = ({ icon, iconPack = 'fad', fill, ...props }) => {
    const theme = useTheme()
    const iconDef = useMemo(() => {
        if (!icon) {
            return null
        }

        if (icon.startsWith('fa')) {
            // We're sending through a font awesome named icon rather than a theme icon
            return [iconPack, getFaIconName(icon)]
        } else {
            return get(theme, `stackerIcons.${icon}`)
        }
    }, [icon, iconPack, theme])

    if (!iconDef) return null

    return <FontAwesomeIcon icon={iconDef} color={fill as string} {...props} />
}

export type IconProps = BoxProps & {
    icon?: string
    size?: string
    label?: string
    button?: boolean
    labelPlacement?: ToolipProps['placement']
    svg?: any
    display?: string
    className?: string
    outerClassName?: string
    iconPack?: string
    topNav?: boolean
}

const Icon: FC<IconProps> = ({
    icon = '',
    size = undefined,
    label = undefined,
    button = false,
    svg = undefined,
    labelPlacement = 'left',
    display = 'flex',
    className = undefined,
    outerClassName = undefined,
    fill,
    iconPack = undefined,
    topNav,
    ...props
}) => {
    const isMobile = useIsMobile()
    const transformIcons = topNav ? { transform: 'translate(2px,11px)' } : {}
    const transformIconsMobile = topNav ? { transform: 'translate(2px,7px)' } : {}

    return (
        <ConditionalWrapper
            condition={!!label}
            wrapper={(children: React.ReactNode) => (
                <Tooltip placement={labelPlacement} label={label} display={display}>
                    {children}
                </Tooltip>
            )}
        >
            <Box
                display={display}
                fontSize={icon ? size : undefined}
                aria-label={label}
                role={button ? 'button' : undefined}
                rounded="full"
                p={button ? 2 : undefined}
                cursor={button ? 'pointer' : undefined}
                _hover={button ? { bg: 'grey.200' } : undefined}
                alignItems="center"
                className={outerClassName}
                justifyContent="center"
                style={isMobile ? transformIconsMobile : transformIcons}
                {...props}
            >
                {icon && (
                    <FaIcon className={className} icon={icon} iconPack={iconPack} fill={fill} />
                )}
                {svg && <Box as={svg.type} size={size} fill={fill} className={className} />}
            </Box>
        </ConditionalWrapper>
    )
}

export default React.memo(Icon)
