import React from 'react'

import { useReauthenticateConnection } from 'data/hooks/dataConnections/useReauthenticateConnection'
import { useSyncConnection } from 'data/hooks/dataConnections/useSyncConnection'
import { DC_TYPE_TO_INTEGRATION_ID, TRANSLATIONS } from 'features/DataConnections/constants'
import { useNewDataConnectionModal } from 'features/DataConnections/useNewDataConnectionModal'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { useToast } from 'ui/components/Toast'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

type DataConnectionGroupOptionsProps = {
    nangoConnectionId: string
    dataConnectionsType: DataConnectionType
}
export const DataConnectionGroupOptions: React.FC<DataConnectionGroupOptionsProps> = ({
    nangoConnectionId,
    dataConnectionsType,
}) => {
    const toast = useToast()

    const { show: showNewDataConnectionModal } = useNewDataConnectionModal()
    const { mutateAsync: syncConnection } = useSyncConnection({
        onError: () => {
            toast({
                title: 'There was a problem triggering sync for the connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { reAuthenticateConnection } = useReauthenticateConnection()

    const integrationId = DC_TYPE_TO_INTEGRATION_ID[dataConnectionsType]

    return (
        <Box
            style={{
                paddingRight: theme.space.xs,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        startIcon={{ name: 'RefreshCcw' }}
                        label="Force full re-sync"
                        onClick={() =>
                            syncConnection({
                                nangoConnectionId: nangoConnectionId,
                                integrationId,
                            })
                        }
                    />
                    <DropdownItem
                        startIcon={{ name: 'Key' }}
                        label="Re-authenticate"
                        onClick={async () => {
                            await reAuthenticateConnection({
                                nangoConnectionId,
                                integrationId,
                            })
                        }}
                    />
                    <DropdownItem
                        startIcon={{ name: 'Plus' }}
                        label={`Sync another ${TRANSLATIONS[integrationId].database} or ${TRANSLATIONS[integrationId].table}`}
                        // TODO: open up connection modal and prefill with context about this one
                        onClick={() =>
                            showNewDataConnectionModal({
                                initialSelectedExternalAccountId: nangoConnectionId,
                            })
                        }
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}
