import { useCallback } from 'react'

import { getNangoClient } from 'features/DataConnections/getNangoClient'

import { useToast } from 'ui/components/Toast'

import { useCreateNangoReconnectSessionToken } from './useCreateNangoReconnectSessionToken'

export const useReauthenticateConnection = () => {
    const toast = useToast()

    const { mutateAsync: createReconnectSessionToken, isLoading: isCreatingReconnectSessionToken } =
        useCreateNangoReconnectSessionToken({
            onError: () => {
                toast({
                    title: 'There was a problem setting up a reconnection. Please try again or contact support.',
                    type: 'error',
                })
            },
        })

    const reAuthenticateConnection = useCallback(
        async ({
            nangoConnectionId,
            integrationId,
        }: {
            nangoConnectionId: string
            integrationId: 'airtable'
        }) => {
            const reconnectSessionToken = await createReconnectSessionToken({
                nangoConnectionId,
                integrationId,
            })

            const nango = getNangoClient(integrationId, reconnectSessionToken.token)
            await nango.auth(integrationId)
        },
        [createReconnectSessionToken]
    )
    return { reAuthenticateConnection, isCreatingReconnectSessionToken }
}
