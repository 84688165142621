import React from 'react'

import { Box } from 'ui/components/Box'

import { useDetailViewTabControlState } from './hooks/useDetailViewTabControlState'
import {
    DetailViewSegmentedControl,
    DetailViewSegmentedControlItem,
} from './DetailViewSegmentedControl'

type DetailViewTabControlProps = {}

export const DetailViewTabControl: React.FC<DetailViewTabControlProps> = ({ children }) => {
    const { value, setValue, tabs } = useDetailViewTabControlState({ children })

    return (
        <Box flex column overflow="hidden" height="full">
            <Box px="l" noShrink>
                <DetailViewSegmentedControl value={value} onValueChange={setValue} role="tablist">
                    {tabs.map((tab) => (
                        <DetailViewSegmentedControlItem
                            key={tab.value}
                            value={tab.value}
                            role="tab"
                            id={`${tab.value}-tab`}
                            aria-controls={`${tab.value}-tabpanel`}
                        >
                            {tab.label}
                        </DetailViewSegmentedControlItem>
                    ))}
                </DetailViewSegmentedControl>
            </Box>
            {tabs.map((tab) => (
                <Box
                    key={tab.value}
                    mt="3xl"
                    mb="3xl"
                    role="tabpanel"
                    id={`${tab.value}-tabpanel`}
                    aria-selected={tab.value === value}
                    aria-labelledby={`${tab.value}-tab`}
                    display={tab.value === value ? 'block' : 'none'}
                    overflowY="auto"
                    shrink
                    grow
                    maxHeight="full"
                >
                    {tab.children}
                </Box>
            ))}
        </Box>
    )
}

type DetailViewTabControlItemProps = {
    value: string
    label: string
}

export const DetailViewTabControlItem: React.FC<DetailViewTabControlItemProps> = () => {
    return null
}
