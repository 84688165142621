import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { RichTextAttributeDisplay } from 'features/views/attributes/RichTextAttributeDisplay'
import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import { RichTextValue } from 'features/views/attributes/types'

import { Body } from 'ui/components/Text'

import { useDetailViewTitleAttributeState } from './hooks/useDetailViewTitleAttributeState'

const MAX_LINES = 1

type DetailViewTitleAttributeProps = Pick<
    React.ComponentProps<typeof Body>,
    'style' | 'className' | 'size'
> & {
    isLoading?: boolean
}

export const DetailViewTitleAttribute: React.FC<DetailViewTitleAttributeProps> = React.memo(
    function DetailViewTitleAttribute(props) {
        const { field, value } = useDetailViewTitleAttributeState()

        if (!field) {
            return null
        }

        switch (field.type) {
            case 'string':
                return (
                    <TextAttributeDisplay
                        key={field._sid}
                        field={field}
                        value={value as string}
                        alwaysShowOverflowTooltip
                        maxLines={MAX_LINES}
                        {...props}
                    />
                )

            case 'long_text':
                return (
                    <LongTextAttributeDisplay
                        key={field._sid}
                        field={field}
                        value={value as string}
                        alwaysShowOverflowTooltip
                        maxLines={MAX_LINES}
                        {...props}
                    />
                )

            case 'document':
                return (
                    <RichTextAttributeDisplay
                        key={field._sid}
                        field={field}
                        value={value as RichTextValue}
                        alwaysShowOverflowTooltip
                        maxLines={MAX_LINES}
                        {...props}
                    />
                )

            default:
                return (
                    <Body key={field._sid} maxLines={MAX_LINES} {...props}>
                        {!!value && String(value)}
                    </Body>
                )
        }
    }
)
