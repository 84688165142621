import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { getUrl } from 'app/UrlService'
import { useFields } from 'data/hooks/fields'
import { createRecord } from 'data/hooks/records/recordOperations'
import { useBreadcrumbsContext } from 'features/breadcrumbs/hooks/useBreadcrumbsContext'
import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { PathContext } from 'features/utils/PathContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { RecordManagerContextProvider } from 'features/views/RecordManager/RecordManagerContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useToast } from 'ui/components/Toast'

import { DetailViewContext } from './hooks/useDetailViewContext'
import { DeleteRecordConfirmModal } from './DeleteRecordConfirmModal'
import { filterSupportedFields, getDetailViewHeader } from './utils'

export type DetailViewContextProviderProps = {}

export const DetailViewContextProvider: React.FC<DetailViewContextProviderProps> = ({
    children,
}) => {
    const { isEditing } = useLayoutEditorContext()
    const { recordId: recordSid, object, pageUrl } = useContext(PathContext)

    const includeFields = isEditing ? undefined : undefined

    return (
        <RecordManagerContextProvider recordSid={recordSid} includeFields={includeFields}>
            <DetailViewContextProviderInner
                object={object as ObjectDto}
                pageUrl={pageUrl}
                includedFields={includeFields}
            >
                {children}
            </DetailViewContextProviderInner>
        </RecordManagerContextProvider>
    )
}

type DetailViewContextProviderInnerProps = {
    object?: ObjectDto
    pageUrl?: string
    includedFields?: string[]
}

const DetailViewContextProviderInner: React.FC<DetailViewContextProviderInnerProps> = React.memo(
    function DetailViewContextProviderInner({ children, object, pageUrl, includedFields = [] }) {
        const { view } = useLayoutEditorContext()

        const recordManager = useRecordManagerContext()
        const recordRef = useRef(recordManager.record)
        recordRef.current = recordManager.record

        const { data: fields = [] } = useFields({ objectId: view?.object_id })
        const supportedFields = filterSupportedFields(fields)
        const fieldsMemo = useDeepEqualsMemoValue(supportedFields)

        const header = getDetailViewHeader(view?.layout, supportedFields)

        const viewPath = pageUrl

        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
        const deleteRecord = useCallback(() => {
            setIsDeleteModalOpen(true)
        }, [])

        const history = useHistory()
        const toast = useToast()
        const duplicateRecord = useCallback(async () => {
            const record = recordRef.current
            if (!record || !object?._sid) return

            try {
                const recordClone = { ...record, _sid: null, object_id: object?._sid }

                const newRecord = await createRecord(recordClone, undefined, false)

                toast({
                    title: 'Record duplicated successfully',
                    startIcon: { name: 'CheckCircle2' },
                    type: 'success',
                })

                const newViewPath = getUrl(`${object?.url}/view/${newRecord?._sid}`)
                history.push(newViewPath)
            } catch {
                toast({
                    title: 'Failed to duplicate record',
                    startIcon: { name: 'AlertCircle' },
                    type: 'error',
                })
            }
        }, [history, object?._sid, object?.url, toast])

        const [isPreviewing] = useIsBannerShowing()

        useHistoryBreadcrumb({
            title: recordManager.record?._primary,
            type: 'detail',
            objectId: view?.object_id,
        })

        const { breadcrumbs, prevItem } = useBreadcrumbsContext()

        const dereferencedRecords = useDeepEqualsMemoValue(
            recordManager.record?._dereferenced_records ?? []
        )

        const contentWidth = view?.layout?.contentWidth ?? 'full'
        const widgetStyle = view?.layout?.widgetStyle ?? 'compact'

        const value = useMemo(
            () => ({
                view,
                viewPath,
                recordManager,
                fields: fieldsMemo,
                header,
                object,
                deleteRecord,
                duplicateRecord,
                isPreviewing,
                breadcrumbs,
                prevBreadcrumb: prevItem,
                requestIncludedFields: includedFields,
                dereferencedRecords,
                contentWidth,
                widgetStyle,
            }),
            [
                view,
                viewPath,
                recordManager,
                fieldsMemo,
                header,
                object,
                deleteRecord,
                duplicateRecord,
                isPreviewing,
                breadcrumbs,
                prevItem,
                includedFields,
                dereferencedRecords,
                contentWidth,
                widgetStyle,
            ]
        )

        return (
            <DetailViewContext.Provider value={value}>
                {children}
                <DeleteRecordConfirmModal
                    isOpen={isDeleteModalOpen}
                    onOpenChange={setIsDeleteModalOpen}
                />
            </DetailViewContext.Provider>
        )
    }
)
